<template>
  <div class="pa right1 bottom14">
    <div class="videoViewBox" @click="handClick">
      <el-tooltip content="视频列表" placement="top" effect="light">
        <span class="cp iconfont icon-shipinliebiao f28"></span>
      </el-tooltip>
    </div>
    <div
      class="w370 h50 cfc videoViewBox_list"
      :class="showList ? 'showList' : ''"
    >
      <div @click="changeVideo(1)" class="w80 btn cp">舱内视频</div>
      <div @click="changeVideo(2)" class="w80 btn cp">舱外视频</div>
      <div @click="changeVideo(3)" class="w80 btn cp">无人机视频</div>
    </div>
  </div>
</template>

<script>
import bus from "@/utils/bus.js";
export default {
  data() {
    return {
      showList: false,
    };
  },
  methods: {
    changeVideo(item) {
      bus.$emit("changeVideo", item);
    },
    handClick() {
      this.showList = !this.showList;
    },
  },
};
</script>

<style lang='scss' scoped>
.videoViewBox {
  height: 50px;
  width: 50px;
  border: 1px solid #43ddfe;
  border-radius: 1px;
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
  color: #04d5da;
  box-sizing: border-box;
  background: rgba(22, 29, 45, 0.58);
}

.videoViewBox_list {
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translateX(120px);
  box-sizing: border-box;
  background: rgba(22, 29, 45, 0.58);
  border: 1px solid #43ddfe;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  .btn {
    border-radius: 22px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #43ddfe;
  }
  .btn:hover {
    background: rgba(6, 23, 56, 0.6);
    border: 1px solid #43deff;
    box-shadow: 1px 1px 2px 0 rgba(3, 16, 50, 0.5), inset 0 0 10px 0 #00ffff;
    border-radius: 16px;
  }
}
.showList {
  transition: 1s;
  transform: translateX(-370px);

}
.videoViewBox:hover {
  opacity: 0.8;
}
</style>