<template>
  <!-- :class="isFind ? 'active' : ''" -->
  <div class="cpt-select">
    <div class="select_box" :class="{ color: routeColor, active: isFind }">
      <el-tooltip class="item" effect="dark" content="兴趣点搜索" placement="top">
        <span :class="['iconfont', isFind ? 'icon-sousuo' : 'icon-sousuo-white']"
          @click.stop="isFind = !isFind"></span></el-tooltip>
      <el-form v-if="isFind">
        <el-form-item prop="destination">
          <div class="item-group">
            <el-autocomplete popper-class="home-keyword-search-panel" :popper-append-to-body="false"
              class="autocomplete-dd" v-model="destination.name" :fetch-suggestions="handle_query_address_async"
              placeholder="请输入兴趣点名称搜索例如：高铁站" :trigger-on-focus="false" @select="handle_select_address" clearable
              @clear="destroy_location_icon">
              <template slot-scope="{ item }">
                <div>
                  <span style="font-size: 14px; color: #333">{{
                    item.name
                  }}</span>
                  <span style="font-size: 12px; color: #999; margin-left: 12px">{{ item.address }}</span>
                </div>
              </template>
            </el-autocomplete>
            <!-- <div @click="handle_pick" class="el-icon-location-outline"></div> -->
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { Utils } from "@/lib/cesium";
let handler = null;
let data_srouce = null;
let location_icon = null;
export default {
  data () {
    return {
      isFind: false,
      routeColor: false,
      input: "",
      destination: {
        name: "",
        lng: 0,
        lat: 0,
      },
    };
  },
  components: {},
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    "$route.path": {
      handler (value) {
        if (value == "/home1027") {
          this.routeColor = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handle_query_address_async (address, cb) {
      // console.log(address, cb, '输入搜索搜索');
      // API.MAP.Geo({ keywords: address })
      //   .then((res) => {
      //     cb(res.data.data.rows);
      //   })
      //   .catch((e) => cb([]));
      // API.MAP.KeyWords({ keywords: address })
      //   .then((res) => {
      //     // console.log(res, '搜索结果');
      //     cb(res.data.pois);
      //   })
      //   .catch((e) => cb([]));
      API.MAP.KeyWordsGaoDe({ keywords: address,region:'芜湖' })
        .then((res) => {
          // console.log('首页高德搜索兴趣点', res);
          cb(res.data.pois);
        });
    },
    handle_select_address (item) {
      // console.log(item, '搜索输入');
      let viewer = window.viewer;
      viewer.trackedEntity && (viewer.trackedEntity = undefined);
      viewer.scene.globe.show = true;
      viewer.scene.skyAtmosphere.show = true;
      viewer.scene.globe.showGroundAtmosphere = true;
      // 模式关闭
      window.plate_data_source && (window.plate_data_source.show = false);
      if (item) {
        let location = item.location.split(",");
        this.destination.name = item.name;
        this.destination.lng = Number(location[0]);
        this.destination.lat = Number(location[1]);
        let cesium_layer = this.g_cesium_layer();
        let viewer = cesium_layer.viewer();
        let potiion = Utils.transformWGS842Cartesian({
          lng: this.destination.lng,
          lat: this.destination.lat,
          alt: 1000,
        });
        viewer.camera.flyTo({
          destination: potiion,
        });
        this.init_location_icon(potiion, item);
      }
    },
    /**
     *@Description: 兴趣点添加到地图上
     *@Date: 2023-11-02 14:46:48
     *@Params1: 
     *@Return1: 
    */
    init_location_icon (position, item) {
      console.log(item, 'item兴趣点');
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();

      if (location_icon) {
        // location_icon.position = position;
        viewer.entities.remove(location_icon);
      }
      location_icon = viewer.entities.add({
        position,
        billboard: {
          width: 36,
          height: 36,
          image: require("@/assets/images/icons/icon_location.png"),
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.Bottom,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        },
        label: {
          // text: `${item.name}\r\n${item.address}`,
          text: `${item.name}`,
          orizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          showBackground: false,
          font: "13px monospace bolder",
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          pixelOffset: new Cesium.Cartesian2(0, -18),
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          fillColor: Cesium.Color.fromCssColorString("#FF0000"), // 字体颜色
          // translucencyByDistance: new Cesium.NearFarScalar(0, 0, 1000000, 1),
        }
      });
    },
    destroy_location_icon () {
      if (!location_icon) {
        return location_icon;
      }
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      viewer.entities.remove(location_icon);
      location_icon = null;
    },
  },
  mounted () {
    // 控制重要元素下拉菜单隐藏
    document.addEventListener("click", (e) => {
      const iconEl = document.querySelector(".cpt-select"); // 这里是要隐藏的弹窗 类名
      if (iconEl && !iconEl.contains(e.target)) {
        // contains 方法 就是查看包含关系
        // contains 方法 本身含义：用于判断字符串中是否包含指定的字符或字符串
        // 返回值 :如果包含指定的字符或字符串返回 true，否则返回 false。
        this.isFind = false;
      }
    });
  },
  computed: {
    cesium_layer () {
      return this.g_cesium_layer();
    },
  },
  destroyed () {
    // this.destroy_cesium_handler();
    // this.clear_entities();
  },
  inject: ["g_cesium_layer"],
};
</script>

<style lang="scss" scoped>
.cpt-select {
  text-align: right;

  /deep/.el-autocomplete-suggestion li:hover {
    background-color: #a1b9ee;
  }

  .select_box {
    display: inline-block;
    position: relative;
    width: 48px;
    height: 48px;
    background: rgba(35, 47, 79, 0.75);
    opacity: 0.7;
    // border: 1px solid #04d5da;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    text-align: center;
    line-height: 48px;
    cursor: pointer;

    &.active {
      // width: 412px;
      text-align: left;
      box-sizing: border-box;
      padding-left: 10px;
      height: 48px;
    }

    &:hover {
      border: 1px solid #43deff;
    }
  }
}

.icon-sousuo {
  display: inline-block;
  width: 48px;
  height: 48px;
  cursor: pointer;

  &::before {
    font-size: 24px;
    content: "\e6d5";
    color: #00ffff;
  }
}

.icon-sousuo-white {
  display: inline-block;
  width: 48px;
  height: 48px;
  cursor: pointer;

  &::before {
    font-size: 24px;
    content: "\e6d5";
    color: #ffffff;
  }
}

/deep/ .el-input__inner {
  padding-right: 30px;
  width: 359px !important;
  font-family: MicrosoftYaHei;
  font-size: 16px;
  color: #5baad4;
  letter-spacing: 0;
  text-align: left;
  font-weight: 400;
  background: transparent !important;
  border: 0;
}

/deep/ .el-form-item {
  margin-bottom: 22px;
  width: 50%;
  position: absolute;
  top: 5%;
  left: 12%;
}

/deep/ input {
  font-family: MicrosoftYaHei;
  font-size: 16px;
  color: #5baad4 !important;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
}

::v-deep {
  .el-scrollbar {
    .el-autocomplete-suggestion li:hover {
      background-color: #34466e !important;
    }
  }
}

.color {

  // border: 1px solid #fff !important;
  /deep/ .icon-sousuo::before {
    color: #fff;
  }

  &:hover {
    border: 1px solid #43deff !important;

    /deep/ .icon-sousuo::before {
      color: #43deff;
    }
  }
}
</style>
<style lang="scss">
.home-keyword-search-panel {
  left: 0 !important;

  .el-scrollbar {
    .el-autocomplete-suggestion li:hover {
      background-color: #34466e !important;
    }

    li {
      box-sizing: border-box;
      padding: 0 10px;

      span {
        color: #ffffff !important;
      }
    }
  }
}

.el-autocomplete {
  border: 1px solid #04d5da;
  right: 368px;
  background: rgba(22, 29, 45, 0.58);
}
</style>
