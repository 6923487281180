<template>
  <div
    class="dt-video-main"
    :class="{ 'dt-video-main-full-screen': fullScreen }"
  >
    <div class="header" v-interact>
      <span>{{ title }}</span>
      <div>
        <img
          class="img1"
          src="@/assets/img/full-screen.png"
          v-if="!fullScreen"
          @click="handleFullScreenClick"
          alt=""
        />
        <img
          class="img2"
          src="@/assets/img/un-full-screen.png"
          v-else
          @click="handleFullScreenClick"
          alt=""
        />
        <img
          class="img3"
          src="@/assets/img/i40.png"
          @click="closeVideo"
          alt=""
        />
      </div>
    </div>
    <div class="content">
      <video
        :id="videoId"
        class="video-js vjs-default-skin"
        controls
        preload="auto"
      ></video>
    </div>
  </div>
</template>

<script>
import Hls from "hls.js";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export default {
  props: {
    title: String,
    videoUrl: String, // 接收父组件传递的视频 URL
    fullScreen: Boolean,
  },
  data() {
    return {
      hls: null,
      player: null,
      videoId: `videoPlayer-${Math.random().toString(36).substr(2, 9)}`, // 生成唯一的 id
    };
  },
  methods: {
    closeVideo() {
      this.$emit("closeVideo");
    },
    handleFullScreenClick() {
      this.$emit("fullScreen");
    },
    initializePlayer() {
      const video = document.getElementById(this.videoId);

      // 清理上一个实例，避免内存泄漏
      if (this.hls) {
        this.hls.destroy();
        this.hls = null;
      }

      if (this.player) {
        this.player.dispose();
        this.player = null;
      }

      // 使用 HLS.js 进行播放
      if (Hls.isSupported()) {
        this.hls = new Hls();
        this.hls.loadSource(this.videoUrl);
        this.hls.attachMedia(video);
        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play().catch((error) => {
            console.error("Error playing video:", error);
          });
        });
        this.hls.on(Hls.Events.ERROR, (event, data) => {
          console.error("HLS Error:", data);
        });
      } else if (videojs.canPlayType("application/vnd.apple.mpegurl")) {
        // 使用 video.js 进行播放
        this.player = videojs(this.videoId, {
          sources: [{ src: this.videoUrl, type: "application/x-mpegURL" }],
        });
        this.player.ready(() => {
          this.player.play().catch((error) => {
            console.error("Error playing video:", error);
          });
        });
      } else {
        console.error("Neither HLS.js nor Video.js can play the video");
      }
    },
  },
  watch: {
    videoUrl(newVal) {
      if (newVal) {
        this.initializePlayer(); // 当 videoUrl 变化时，重新初始化播放器
      }
    },
  },
  mounted() {
    this.initializePlayer(); // 初次加载时初始化播放器
  },
  beforeDestroy() {
    if (this.hls) {
      this.hls.destroy();
    }
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style lang="scss" scoped>
.dt-video-main {
  position: fixed;
  width: 600px;
  height: 400px;
  top: 15%;
  left: calc(50% - 300px);
  border: 1px solid #23495b;
  background-color: #23495b;
  z-index: 1000;
  overflow: hidden;
  resize: both;
  .header {
    height: 40px;
    background-color: #14213f;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
.dt-video-main-full-screen {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  transform: unset !important;
}

.content {
  width: 100%;
  height: calc(100% - 40px);
}

.video-js {
  width: 100%;
  height: 100%;
}
</style>
