<template>
  <div class="home-header-left_box">
    <div class="left-list_box">
      <div class="list-item_box">
        <div class="date">{{ date.date }}</div>
        <div class="time">
          <div class="week">{{ date.week }}</div>
          <div class="hour">{{ date.hour }}</div>
        </div>
      </div>

      <div class="list-item_box">
        <div class="wea-detail_box">
          <div class="wea-info-group_box">
            <div class="wea-info_box">
              <div class="wea-title">天气</div>
              <div class="wea-icon_box">
                <img :src="weatherIcon" />
              </div>
              <div class="wea-value">
                {{ (weather && weather.weather) || "阴" }}
              </div>
            </div>
            <div class="wea-info_box">
              <div class="wea-title">温度</div>
              <div class="wea-value">
                {{ (weather && weather.temperature) || 0 }}度
              </div>
            </div>
          </div>
          <div class="wea-info-group_box">
            <div class="wea-info_box">
              <div class="wea-title">风力</div>
              <div class="wea-value">
                <span style="margin-right: 12px">{{
                  (weather && weather.windPower) || "0级 无风 0.0m/s"
                }}</span>
              </div>
            </div>

            <div class="wea-info_box">
              <div class="wea-title">湿度</div>
              <div class="wea-value">
                <div style="display: flex; align-items: center">
                  <span style="margin-right: 12px">
                    {{ (weather && weather.humidity) || 0 }}%</span>
                  <div class="wea-takeoff _1" v-if="weather.flyStatus == 0" style="color: #ffc15b">
                    不宜起飞
                  </div>
                  <div class="wea-takeoff _1" v-else>适合飞行</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  computed: {
    date () {
      return this.g_date();
    },
    // weather() {
    //   return this.g_weather();
    // },
  },
  inject: ["g_date", "handle_report_show", "g_weather", 'g_cesium_resetJfq'],
  data () {
    return {
      data: {},
      list: {
        duty: [],
        check: [],
      },
      filter: {
        name: null,
        orgId: null,
      },
      options: [],
      weather: {
        humidity: 3,
        id: 8,
        light: 100,
        maxTemp: 22.8,
        minTemp: 10.3,
        rain: 0,
        temperature: 14.8,
        weather: "晴",
        weatherCode: "00",
        windDirection: 0,
        windPower: "0级 无风 0m/s",
        windSpeed: 0,
      },
      weatherIcon: null,
    };
  },
  watch: {
    "weather.weather": function (val, old) {
      if (val) {
        this.$store.commit("checksafe/SET_WEATHER", val);
      }
    },
    deep: true,
  },
  methods: {
    handle_detail () {
      let { href } = this.$router.resolve({ path: "/command?page=6" });
      window.open(href, "_blank");
    },
    async getCheckList () {
      // let res = await API.PERSON.CheckInfo(this.filter);
      // this.list.check = res || [];
      this.list.check =  [];
    },
    async get_duty () {
      let data = await API.USER.GetDuty();
      this.data = data;
    },
    async getOptions () {
      let res = await API.DEPARTMENT.Drd();
      this.options = [res] || [];
    },
    async list_duty () {
      let data = await API.USER.ListDuty();
      this.list.duty = data || [];
    },
    handleChange (val) {
      if (val == 0) {
        let _this = this;
        this.$refs.cascader.$refs.panel.$el.addEventListener(
          "click",
          function () {
            _this.$refs.dropdown.show();
          }
        );
      } else {
        this.$refs.dropdown.show();
      }
    },
    init_ws () {
      const data = new Date();
      let time = data.getHours();
      let ws_url_al = process.env.VUE_APP_WS_URL_ALARM;
      let ws = new WebSocket(ws_url_al);
      let _this = this;
      let user_info = localStorage.getItem("user_info");
      let { appid, username } = JSON.parse(localStorage.getItem("user_info")).data
      let token = JSON.parse(localStorage.getItem("user_info")).data["mmc-identity"]

      ws.onopen = () => {
        ws.send(
          JSON.stringify({
            type: 100,
            systemCode: "mmc",
            state: 1,
            username,
            token,
            appId: appid,
          })
        );

      };
      ws.onmessage = (e) => {
        let metadata = JSON.parse(e.data);
        // console.log(metadata, '无人机原始数据，可能引起地图压力过大11');
        if (metadata.msgnum === 4131) {
          let data = metadata.data.filter((item) => {
            return item.id == 8;
          });
          _this.weather = data[0];
          _this.weatherIcon = require(`@/assets/images/weather/icon/${time >= 18 ? "night" : "day"
            }/${_this.weather.weatherCode}.png`);
          // 临时禁飞区
        } else if (metadata.msgnum == 4133) {
          this.g_cesium_resetJfq();
        }
      };

      ws.onerror = (error) => {
        //console.log("error", error);
      };

      ws.onclose = (data) => {
        console.log("onclose", data);
      };
    },
  },
  async mounted () {
    this.init_ws();
    await this.getOptions();
    await this.getCheckList();
    await this.get_duty();
    await this.list_duty();
    // Promise.all([this.get_duty(), this.list_duty()]);
  },
};
</script>

<style lang="scss" scoped>
.home-header-left_box {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 60px;
  z-index: 9;

  .left-list_box {
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    box-sizing: border-box;
    position: relative;

    .list-item_box {
      height: 100%;
      padding: 0 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      .wea-detail_box {
        display: flex;

        .wea-info-group_box {
          display: flex;
          flex-direction: column;
          margin-right: 23px;

          &:last-child {
            margin-right: 0px;
          }
        }

        .wea-info_box {
          height: 50%;
          display: flex;
          align-items: center;

          .wea-title {
            font-family: Microsoft YaHei;
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 0;
            font-weight: 400;
            margin-right: 4px;
          }

          .wea-icon_box {
            margin-right: 4px;
            width: 30px;
            height: 30px;

            img {
              width: 100%;
              height: 100%;
            }

            .iconfont {
              font-size: 30px;
              color: #ffc736;
            }
          }

          .wea-value {
            font-family: Microsoft YaHei;
            font-size: 16px;
            color: #00e4ff;
            letter-spacing: 0;
            font-weight: 700;
          }
        }
      }

      .wea-speed {
        font-family: Microsoft YaHei;
        font-size: 16px;
        color: #00e4ff;
        letter-spacing: 0;
        font-weight: 700;
        height: 50%;
      }

      .wea-takeoff {
        font-family: YouSheBiaoTiHei;
        font-size: 20px;
        letter-spacing: 0;
        font-weight: 400;
        height: 50%;

        &._1 {
          color: #6aff64;
        }

        &._2 {
          color: red;
        }
      }

      &.duty:hover {
        cursor: pointer;
        background: #00e4ff;

        .icon {
          .iconfont {
            color: #19223d;
          }
        }

        .text {
          color: #19223d;
        }
      }

      &::after {
        content: " ";
        display: block;
        width: 1px;
        height: 37px;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(50%);
        background-image: linear-gradient(206deg,
            rgba(36, 146, 252, 0) 5%,
            #2492fc 56%,
            rgba(36, 146, 252, 0) 100%);
      }

      &:last-child::after {
        display: none;
      }

      .date {
        font-family: UniDreamLED;
        font-size: 24px;
        color: #00e4ff;
        letter-spacing: 0;
        font-weight: 400;
        min-width: 110px;
      }

      .time {
        display: flex;

        .week {
          font-family: Microsoft YaHei;
          font-size: 20px;
          color: #00e4ff;
          letter-spacing: 0;
          font-weight: 800;
          margin-right: 8px;
        }

        .hour {
          min-width: 78px;
          font-family: UniDreamLED;
          font-size: 24px;
          color: #00e4ff;
          letter-spacing: 0;
          font-weight: 400;
        }
      }

      .icon {
        display: flex;
        justify-content: center;

        .iconfont {
          font-size: 36px;
          color: #00e4ff;
        }
      }

      .text {
        font-family: Microsoft YaHei;
        font-size: 10px;
        color: #43f0df;
        letter-spacing: 0;
        text-align: center;
        line-height: 10px;
        font-weight: 400;
      }

      .dep-info_box {
        display: flex;

        .dep-group_box {
          margin-right: 11px;

          &:last-child {
            margin-right: 0;
          }

          .dep-box {
            display: flex;
            align-items: center;

            .dep-title {
              font-family: Microsoft YaHei;
              font-size: 14px;
              color: #ffffff;
              letter-spacing: 0;
              font-weight: 400;
              margin-right: 4px;

              .iconfont {
                font-size: 26px;
              }
            }

            .dep-name {
              font-family: MicrosoftYaHei-Bold;
              font-size: 16px;
              color: #00e4ff;
              letter-spacing: 0;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

.duty-list-box {
  border: 1px solid red;
  width: 400px;
  height: 455px;
  background-image: linear-gradient(180deg, #161d2d 0%, #0c2e4f 100%);
  border: 1px solid #00e4ff;
  box-shadow: 0 8px 16px 0 #101523;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 11px 9px;

  .hoverbgcolor {
    img:hover {
      cursor: pointer;
      background-color: rgb(11, 176, 176);
    }
  }

  .search-box {
    display: flex;
    margin-bottom: 15px;
    color: #08c2d1;
    justify-content: space-between;

    .detail {
      width: 45px;
      line-height: 40px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    .icon-daka {
      color: #21e0e0;
      font-size: 28px;
      line-height: 40px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    ::v-deep {

      .el-input,
      .el-cascader {
        width: 150px;
      }

      .el-input__inner {
        background: #000000;
        border: 1px solid #08c2d1;
        font-family: MicrosoftYaHeiUI;
        font-size: 16px;
        color: #08c2d1;
      }

      .el-button {
        width: 114px;
        height: 40px;
        padding: 10px 20px;
        background: #129c9c;
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #ffffff;
        border: none;
      }
    }
  }

  .hd_box {
    margin-bottom: 7px;
    height: 20px;

    .tr {
      display: flex;

      .th {
        flex: 1;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #43c7f0;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
      }
    }
  }

  .bd_box {
    height: calc(100% - 20px);
    overflow: auto;

    .tr {
      display: flex;

      .td {
        flex: 1;
        font-family: Microsoft YaHei;
        font-size: 12px;
        color: #9bffff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        height: 59px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.group {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      &:nth-last-of-type(odd) {
        background: #2b3857;
        box-shadow: inset 0 -1px 0 0 #275a61;
      }

      &:nth-last-of-type(even) {
        background: #1e2a45;
        box-shadow: inset 0 -1px 0 0 #275a61;
      }
    }
  }

  .bd_box_card {
    height: calc(100% - 80px);
  }
}
</style>