<template>
  <div class="cpt-home_header">
    <div class="home-header_inner">
      <HeaderLeft />
      <div class="header-bg_box">
        <div class="header-text">{{ title }}</div>
      </div>
      <HeaderRight :data="user" />
    </div>
  </div>
</template>

<script>
import HeaderLeft from "@/components/header-left";
import HeaderRight from "@/components/header-right";
export default {
  props: {
    title: {
      type: String,
      default: () => "标题",
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { HeaderLeft, HeaderRight },
};
</script>

<style lang="scss" scoped>
.cpt-home_header {
  height: 72px;
  box-sizing: border-box;
  background: #19223d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  .home-header_inner {
    height: 100%;
    position: relative;
    .header-bg_box {
      position: absolute;
      height: 100px;
      top: 0;
      right: 0;
      left: 0;
      background: center -10px url("~@/assets/images/home/header_bg.png") no-repeat;
      background-size: 100% 100%;
      .header-text {
        font-family: PangMenZhengDao;
        font-size: 36px;
        color: #e0ebff;
        text-align: center;
        font-weight: 400;
        line-height: 85px;
        user-select: none;
      }
    }
  }
}
</style>
