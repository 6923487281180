<template>
  <div class="pa right1 auxiliaryBox">
    <div class="videoViewBox" @click="handClick">
      <el-tooltip content="辅助" placement="top" effect="light">
        <span class="cp iconfont icon-fuzhu f28"></span>
      </el-tooltip>
    </div>
    <div
      class="w370 h160 cfc videoViewBox_list"
      :class="showList ? 'showList' : ''"
    >
      <div class="auxiliaryList">
        <span class="iconfont icon-uav f25 dib mr20" />
        <div class="btn mr10" @click="auxiliaryFly">一键任务</div>
        <div class="btn mr10" @click="auxiliary(0)">一键返航</div>
        <div class="btn" @click="auxiliary(1)">紧急迫降</div>
      </div>
      <div class="divider"></div>
      <div class="auxiliaryList mt10">
        <span class="iconfont icon-yingchao f25 dib mr10" />
        <div class="btn mr10 mb10" @click="auxiliary(2)">开始充电</div>
        <div class="btn mr10 mb10" @click="auxiliary(3)">结束充电</div>
        <div class="btn mr10" @click="auxiliary(4)">预热</div>
        <div class="btn ml38 mr16" @click="auxiliary(5)">休眠</div>
        <div class="btn" @click="auxiliary(6)">退出流程</div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/utils/bus.js";
export default {
  data() {
    return {
      showList: false,
      list: [
        { label: "一键返航", data: { cmdControlType: 100003 } },
        { label: "原地降落", data: { cmdControlType: 104 } },

        {
          label: "开始充电",
          data: {
            cmdControlType: 2060,
          },
        },
        {
          label: "结束充电",
          data: {
            cmdControlType: 2071,
          },
        },
        {
          label: "预热",
          data: {
            cmdControlType: 2072,
          },
        },
        {
          label: "休眠",
          data: {
            cmdControlType: 2061,
          },
        },
        {
          label: "退出流程",
          data: {
            cmdControlType: 100001,
          },
        },
      ],
    };
  },
  methods: {
    auxiliary(item) {
      let waypointList = JSON.parse(sessionStorage.getItem("waypointList"));
      if (item == 0) {
        bus.$emit(
          "auxiliary",
          {
            cmdControlType: 100003,
            uavDeviceId: waypointList.uavDeviceId,
          },
          "yc"
        );
        this.$message.success("操作成功");
      } else if (item == 1) {
        bus.$emit("auxiliary", { type: 516 }, "wrj");
        this.$message.success("操作成功");
      } else {
        bus.$emit("auxiliary", this.list[item].data, "yc");
        console.log(this.list[item].data, "机巢控制");
        this.$message.success("操作成功");
      }
    },
    auxiliaryFly() {
      let waypointList = JSON.parse(sessionStorage.getItem("waypointList"));
      if (waypointList && waypointList.uavDeviceId) {
        bus.$emit(
          "auxiliary",
          {
            cmdControlType: 100004,
            uavDeviceId: waypointList.uavDeviceId,
            wayLineObj: waypointList.wayLineObj,
          },
          "yc"
        );
        this.$message.success("操作成功");
      } else {
        sessionStorage.removeItem("waypointList");
        this.$message.warning("请选择任务");
      }
    },
    handClick() {
      this.showList = !this.showList;
    },
  },
};
</script>

<style lang="scss" scoped>
.auxiliaryBox {
  bottom: -40px;
}
.videoViewBox {
  height: 50px;
  width: 50px;
  border: 1px solid #43ddfe;
  border-radius: 1px;
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
  color: #04d5da;
  box-sizing: border-box;
  background: rgba(22, 29, 45, 0.58);
}

.videoViewBox_list {
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translateX(120px);
  box-sizing: border-box;
  background: rgba(22, 29, 45, 0.58);
  border: 1px solid #43ddfe;
  padding: 10px;
  .auxiliaryList {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .btn {
    width: 90px;
    border-radius: 22px;
    height: 30px;
    cursor: pointer;
    line-height: 30px;
    border: 1px solid #43ddfe;
  }
  .btn:hover {
    background: rgba(6, 23, 56, 0.6);
    border: 1px solid #43deff;
    box-shadow: 1px 1px 2px 0 rgba(3, 16, 50, 0.5), inset 0 0 10px 0 #00ffff;
    border-radius: 16px;
  }
}
.showList {
  transition: 1s;
  transform: translateX(-370px);
}
.videoViewBox:hover {
  opacity: 0.8;
}
.divider {
  flex-shrink: 0;
  //   margin: 10px 10px 0;
  width: 100%;
  height: 1px;
  //   transform: scaleY(0.4);
  background-image: linear-gradient(
    to right,
    rgba(0, 117, 221, 0) 0%,
    #23b8ff 50%,
    rgba(0, 117, 221, 0) 100%
  );
  margin-top: 20px;
}
</style>
