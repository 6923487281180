import API from '@/api'
import {
  measure,
  measureElevation,
  measureTypes,
  drawGraphic,
  getArea,
} from '@/lib/cesium/measure'

let handler = null
let current = null
let entities = []
let wallEntities = []
let backShapePoints = []
let lonlats = []
let baseHeight = 0
let triangle = []
let minWallHeight = 0
let maxWallHeight = 0
let excavateVolume = ''
let buryVolumes = ''
let labelEntity = null
let baseEntity = null
let showVolumeDiaolog = false
let draw = false

export default {
  handle_click(e,val) {
    console.log('handle_click', e, val);
    let viewer = window.viewer
    switch (e) {
      case 1:
        this.toolbar_flyto_origin(viewer)
        break
      case 2:
        this.toolbar_set_origin(viewer)
        break
      case 3:
        this.toolbar_measure(viewer, 1)
        break
      case 4:
        this.toolbar_measure(viewer, 2)
        break
      case 5:
        this.toolbar_measure(viewer, 3)
        break
      case 6:
        this.toolbar_measure(viewer, 4)
        break
      case 7:
        this.toolbar_view_top(viewer)
        break
      case 8:
        this.toolbar_view_look_down(viewer)
        break
      case 9:
        this.toolbar_view_north(viewer)
        break
      case 10:
        this.toolbar_view_eyes(viewer)
        break
      case 11:
        this.factor_chage_not_fly_area(val)
        break
    }
  },

  /**
   * 飞入原点
   */
  async flyto_origin(viewer) {

    let res = await API.USER.GetOrigin()
    if (_.isEmpty(res)) {
      return this.$el_message('暂未设置原点')
    }
    viewer.scene.globe.show = true;
    window.plate_data_source && (window.plate_data_source.show = false);
    let data = {
      destination: new Cesium.Cartesian3(res.lng, res.lat, res.height || 300), //相机位置
      orientation: {
        heading: res.heading || 0,
        pitch: res.pitch || 0,
        roll: res.roll || 0,
      },
    }
    viewer.camera.flyTo(data)
  },

  /**
   * 设置原点
   */
  async set_origin(viewer) {
    let user_id = this.user_info['uid']
    this.$el_confirm('确定要设置此视角点为原点吗？', () => {
      let {
        camera: {
          position: {
            x,
            y,
            z
          },
          heading,
          pitch,
          roll,
        },
      } = viewer
      API.USER.UpdateOrigin({
        lng: x,
        lat: y,
        height: z,
        heading,
        pitch,
        roll,
      })
        .then(() => this.$el_message('设置成功'))
        .catch(() => this.$el_message('设置失败'))
    })
  },

  measure(viewer, cate) {
    const {
      toolbar_handle_measure
    } = this
    if (cate == 1) {
      toolbar_handle_measure(viewer, measureTypes.HORL)
    } else if (cate == 2) {
      toolbar_handle_measure(viewer, measureTypes.VERL)
    } else if (cate == 3) {
      toolbar_handle_measure(viewer, measureTypes.AREA)
    } else if (cate == 4) {
      toolbar_handle_measure(viewer, measureTypes.VOLUME)
    }
  },

  handle_measure(viewer, type) {
    let drawingMode = type === measureTypes.AREA ? 'polygon' : 'line'

    if (wallEntities) {
      wallEntities.forEach((entity) => {
        viewer.entities.remove(entity)
      })
      wallEntities = null
    }

    if (handler && handler.destroy) {
      entities.forEach((item) => {
        viewer.entities.remove(item)
      })
      if (!handler.isDestroyed()) handler.destroy() // 关闭绘制事件句柄
      //viewer.scene.globe.depthTestAgainstTerrain = false; // 关闭地形深度检测
      handler = null
    }

    if (current !== type) {
      if (type == measureTypes.VOLUME) {
        showVolumeDiaolog = true
        this.toolbar_handle_measure_volume(viewer)
      } else if (type === measureTypes.VERL) {
        handler = measureElevation(
          viewer,
          (backShapePoints, backShapeEntities) => {
            current = ''
            entities = backShapeEntities
            //viewer.scene.globe.depthTestAgainstTerrain = false; // 关闭地形深度检测
          }
        )
      } else {
        handler = measure(
          viewer,
          drawingMode,
          (backShapePoints, backShapeEntities) => {
            entities = backShapeEntities
            // let position = Cesium.Cartesian3.lerp(backShapePoints[0], backShapePoints[1], 0.5, new Cesium.Cartesian3())
            const position = Cesium.Cartesian3.midpoint(
              backShapePoints[0],
              backShapePoints[1],
              new Cesium.Cartesian3()
            )

            const cartographic = Cesium.Cartographic.fromCartesian(position)

            viewer.entities.add({
              position,
              point: {
                pixelSize: 6,
                color: Cesium.Color.GREEN,
                outlineColor: Cesium.Color.GREEN,
                outlineWidth: 0,
              },
            })
          }
        )
      }

      current = type
    } else {
      current = ''
      showVolumeDiaolog = false
    }
  },

  handle_measure_volume(viewer) {
    draw = true

    drawGraphic(viewer, 'polygon', (backShapePoints, backShapeEntities) => {
      if (backShapePoints.length < 3) {
        this.$el_message('请最少选择三个点', () => { }, 'warning')
        this.toolbar_handle_measure_volume(viewer)
        return false
      }

      draw = false
      backShapeEntities.forEach((entity) => {
        viewer.entities.remove(entity)
      })

      this.toolbar_volume_analysis(viewer, backShapePoints)

      handler = null
      current = null
      entities = []
      // wallEntities = [];
      backShapePoints = []
      lonlats = []
      baseHeight = 0
      triangle = []
      minWallHeight = 0
      maxWallHeight = 0
      excavateVolume = ''
      buryVolumes = ''
      labelEntity = null
      baseEntity = null
    })
  },

  volume_analysis(viewer, points) {
    //viewer.scene.globe.depthTestAgainstTerrain = true; // 开启地形深度检测

    let minHeight = 10000
    for (let i = 0; i < points.length; i++) {
      const cartographic = Cesium.Cartographic.fromCartesian(points[i])
      const height = viewer.scene.sampleHeight(cartographic)

      if (minHeight > height) {
        minHeight = height
      }

      // 经纬度值
      const lng = Cesium.Math.toDegrees(cartographic.longitude)
      const lat = Cesium.Math.toDegrees(cartographic.latitude)
      lonlats.push(lng, lat, 0)
    }

    // 设置网格粒度
    let granularity = Math.PI / Math.pow(2, 15)
    granularity /= 64
    // 创建多边形平面几何体
    // @ts-ignore
    const polygonGeometry = new Cesium.PolygonGeometry.fromPositions({
      positions: points,
      vertexFormat: Cesium.PerInstanceColorAppearance.FLAT_VERTEX_FORMAT,
      granularity,
    })

    // 创建多边形平面几何体
    // @ts-ignore
    const geom = new Cesium.PolygonGeometry.createGeometry(polygonGeometry)
    const totalVolume = 0
    let maxHeight = 0
    let i0
    let i1
    let i2
    let height1
    let height2
    let height3
    let p1
    let p2
    let p3
    let cartesian
    // @ts-ignore
    let cartographic
    let bottomArea
    let buryVolumes = 0
    let excavateVolume = 0
    let cartesian1
    let cartesian2
    let cartesian3

    // 循环计算网格的节点, indices顶点索引数据，用于确定几何中的基元
    for (
      let i = 0; i < geom.indices.length && geom.indices.length < 10000; i += 3
    ) {
      i0 = geom.indices[i]
      i1 = geom.indices[i + 1]
      i2 = geom.indices[i + 2]
      // 获取几何体三角面第一个面点
      cartesian1 = new Cesium.Cartesian3(
        geom.attributes.position.values[i0 * 3],
        geom.attributes.position.values[i0 * 3 + 1],
        geom.attributes.position.values[i0 * 3 + 2]
      )
      // 获取几何体三角面第二个面点
      cartesian2 = new Cesium.Cartesian3(
        geom.attributes.position.values[i1 * 3],
        geom.attributes.position.values[i1 * 3 + 1],
        geom.attributes.position.values[i1 * 3 + 2]
      )
      // 获取几何体三角面第三个面点
      cartesian3 = new Cesium.Cartesian3(
        geom.attributes.position.values[i2 * 3],
        geom.attributes.position.values[i2 * 3 + 1],
        geom.attributes.position.values[i2 * 3 + 2]
      )
      // @ts-ignore
      const polyCenter = Cesium.BoundingSphere.fromPoints([
        cartesian1,
        cartesian2,
        cartesian3,
      ]).center
      // @ts-ignore
      cartographic = Cesium.Cartographic.fromCartesian(polyCenter)

      const height = viewer.scene.sampleHeight(cartographic)

      // @ts-ignore
      if (maxHeight < height) {
        // @ts-ignore
        maxHeight = height
      }

      // 计算三角面的面积
      // @ts-ignore
      bottomArea = getArea([cartesian1, cartesian2, cartesian3])

      triangle.push({
        height,
        bottomArea,
      })

      if (height > minHeight) {
        // @ts-ignore
        excavateVolume += bottomArea * (height - minHeight)
      }

      if (height < minHeight) {
        // @ts-ignore
        buryVolumes += bottomArea * (minHeight - height)
      }
    }

    // 创建体积墙
    const text = `挖方体积：${excavateVolume.toFixed(
      1
    )}立方米\n填方体积：${buryVolumes.toFixed(1)}立方米`
    this.toolbar_create_wall(
      Cesium,
      viewer,
      lonlats,
      minHeight,
      maxHeight,
      points,
      text
    )

    baseHeight = Number(minHeight.toFixed(2))
    minWallHeight = Number(minHeight.toFixed(2))
    maxWallHeight = Number(maxHeight.toFixed(2))
    excavateVolume = `${excavateVolume.toFixed(1)}立方米`
    buryVolumes = `${buryVolumes.toFixed(1)}立方米`
  },

  create_wall(
    Cesium,
    viewer,
    lonlats,
    minHeight,
    maxHeight,
    points,
    totalVolume
  ) {
    const maxLonlats = lonlats.map((item) => {
      if (item === 0) {
        return maxHeight
      }
      return item
    })

    const centroid = Cesium.BoundingSphere.fromPoints(
      Cesium.Cartesian3.fromDegreesArrayHeights(maxLonlats)
    ).center
    labelEntity = new Cesium.Entity({
      // @ts-ignore
      position: centroid,
      label: {
        // @ts-ignore
        text: totalVolume,
        font: '14pt Microsoft YaHei',
        // @ts-ignore
        showBackground: true,
        // @ts-ignore
        backgroundColor: Cesium.Color.BLACK.withAlpha(0.5),
        // @ts-ignore
        perPositionHeight: true,
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(
          1.0,
          50000.0
        ),
        // @ts-ignore
        disableDepthTestDistance: 5000.0,
      },
    })
    viewer.entities.add(labelEntity)

    // @ts-ignore
    const entity = new Cesium.Entity({
      // @ts-ignore
      polygon: {
        // @ts-ignore
        hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights(maxLonlats),
        material: Cesium.Color.CYAN.withAlpha(0.4),
        extrudedHeight: 0,
        // @ts-ignore
        perPositionHeight: true,
        closeTop: false,
        closeBottom: false,
      },
    })
    viewer.entities.add(entity)

    const minLonlats = lonlats.map((item) => {
      if (item === 0) {
        return minHeight
      }
      return item
    })

    baseEntity = new Cesium.Entity({
      // @ts-ignore
      polygon: {
        // @ts-ignore
        hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights(minLonlats),
        material: Cesium.Color.GREEN.withAlpha(0.4),
        // @ts-ignore
        clampToGround: false,
        // @ts-ignore
        perPositionHeight: true,
      },
    })
    viewer.entities.add(baseEntity)
    wallEntities = [labelEntity, entity, baseEntity]
  },

  /**
   * 顶视
   */
  view_top(viewer) {
    viewer.camera.flyTo({
      destination: viewer.camera.position, // 相机位置
      orientation: {
        heading: viewer.camera.heading,
        pitch: Cesium.Math.toRadians(-90),
        roll: 0.0,
      },
    })
  },

  /**
   * 俯视
   */
  view_look_down(viewer) {
    viewer.camera.flyTo({
      destination: viewer.camera.position, // 相机位置
      orientation: {
        heading: viewer.camera.heading,
        pitch: Cesium.Math.toRadians(-45),
        roll: 0.0,
      },
    })
  },

  /**
   * 指北
   */
  view_north(viewer) {
    viewer.camera.flyTo({
      destination: viewer.camera.position, // 相机位置
      orientation: {
        heading: Cesium.Math.toRadians(0),
        pitch: viewer.camera.pitch,
        roll: 0.0,
      },
    })
  },

  /**
   * 人视角
   */
  view_eyes(viewer) {
    viewer.camera.flyTo({
      destination: viewer.camera.position, // 相机位置
      orientation: {
        heading: viewer.camera.heading,
        pitch: Cesium.Math.toRadians(-10),
        roll: 0.0,
      },
    })
  },
}