import API from "@/api"
import {
  Viewer,
  Utils
} from "@/lib/cesium"
import { Log } from "@antv/scale"

function tree2list (tree, list = []) {
  tree.forEach(item => {
    list.push({
      ...item
    })
    if (item.children && item.children.length) {
      list.push(...tree2list(item.children))
    }
  })
  return list
}

function findRoot (curr, list) {
  if (curr.pid <= 0) {
    return curr
  }
  while (curr.pid > 0) {
    curr = list.find(item => item.cate_id == curr.pid)
  }
  return curr
}

const FACTOR_TYPE = Object.freeze({
  ZYSB: 1,
  JWYS: 2,
  GZSB: 3,
  JLYS: 4,
  KFQ: 5
})

let not_fly_area_entities = []
let POLYGONArr = []

export default {

  async shang_change (e, is_show, id) {
    console.log('shang_change', e, is_show, id);
    let viewer = window.viewer
    viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(120.149356, 33.379614, 100000.0),
    })
    let res
    let cate
    let root = e
    let is_total_hide = []
    root.children.forEach(item => {
      is_total_hide.push(item.checked)
    })
    // let id = root.children.map(item => item.id==4)
    this.factor_shang_hide(e, id)
    if (is_total_hide.includes(true)) {
      cate = root.cate_id
      let id = root.children.filter(item => item.checked).map(item => item.id)
      let flag = false
      // 电力塔
      if (id == 4 && root.pid == -1) {
        cate = 6
        id = 7
        flag = true
      }
      console.log('cate, id', cate, id);
      res = await API.FACTOR.List({
        cate,
        id: id
      })
      // 代表是电力塔
      if (flag) {
        cate = 7
      }
      switch (cate) {
        case 1:
          this.factor_add_zysb(res);
          break;
        case 2:
          this.factor_add_jwys(res);
          break;
        case 3:
          this.factor_add_gzsb(res);
          break;
        case 4:
          this.factor_add_jlys(res)
          break;
        case 5:
          this.factor_add_kfq(res);
          break;
        case 6:
          this.factor_add_zddq(res);
          break;
        case 7:
          this.factor_add_dlt(res);
          break;
      }
    }

  },

  shang_hide (e, id) {
    console.log(id, 'idid');
    let root = e
    let cate = root.cate_id
    if (id == 4) {
      cate = 7
    }
    switch (cate) {
      case 1:
        this.factor_hide_collection("zysb")
        break;
      case 2:
        this.factor_hide_collection("jwys")
        break;
      case 3:
        this.factor_hide_collection("gzsb")
        break;
      case 4:
        this.factor_hide_collection("jlys")
        break;
      case 5:
        this.factor_hide_collection("kfq")
        break;
      case 6:
        this.factor_hide_collection("zddq")
        break;
      case 7:
        this.factor_hide_collection("dlt")
        break;
    }
  },

  async show_xfq () {
    // 禁飞区
    let data1 = await API.AIRSPACE.GetNotFlyAreas()
    // console.log('禁飞区', data1);
    // 电子围栏
    let data2 = await API.AIRSPACE.GetAreaList();
    // 当前机构自己的电子围栏
    let data3 = await API.AIRSPACE.GetCurrentAreaList();
    // 限高区
    let data4 = await API.AIRSPACE.GetLimitHeightAreas();
    // 新添加的区域 2024年3月27日 14:30:08
    let data5 = await API.AIRSPACE.getFlightSpaceGetAreas();
    // 区域线
    let data6 = await API.AIRSPACE.getAirspaceLineList();
    // 区域面
    let data7 = await API.AIRSPACE.getAirspacePointList();

    data3.forEach(item => {
      item.typeId = 4
    })
    let _this = this
    let data = [...data1, ...data2, ...data3, ...data4, ...data5, ...data6, ...data7] || []
    // console.log('datadatadatadatadatadatadatadatadata', data);
    data.forEach(item => {
      // TODO: 去掉糖果等图层
      // _this.factor_init_area(item.regionType, item.regionList, item)
    });

    // window.viewer.scence.requestRender()
    return data
  },
  async resetJfq () {
    let _this = this
    if (POLYGONArr.length > 0) {
      POLYGONArr.forEach(item => {
        _this.factor_removeJfq(item)
      })
    }
    this.factor_show_xfq()
    // let data = await API.AIRSPACE.GetNotFlyAreas();
    // if (data.length > 0) {
    //     data.forEach(item => {
    //         _this.factor_init_area(item.regionType, item.regionList, item)
    //     })
    // }

  },
  removeJfq (POLYGON) {
    window.viewer.entities.remove(POLYGON);
  },
  chage_not_fly_area (e) {
    not_fly_area_entities.forEach(item => {
      item.show = e
    })
  },
  // 删除所有实体
  entities () {
    let viewer = this.cesium_layer.viewer()
    viewer.entities.removeAll()
  },
  init_area (regionType, positions, item) {
    // console.log(this.cesium_layer, 'this.cesium_layer');
    if (!this.cesium_layer) {
      return;
    }
    // return
    let viewer = this.cesium_layer.viewer()
    let not_fly_entitie = null
    if (regionType == "CIRCLE") {
      not_fly_entitie = viewer.entities.add({
        position: Cesium.Cartesian3.fromDegrees(positions[0], positions[1]),
        ellipse: {
          semiMajorAxis: positions[2],
          semiMinorAxis: positions[2],
          height: 8,
          // material: item.typeId == 2 ? Cesium.Color.GREEN.withAlpha(0.2) : (item.typeId == 4 ? Cesium.Color.GREEN.withAlpha(0.7) : (item.typeId == 3 ? Cesium.Color.YELLOW.withAlpha(0.2) : Cesium.Color.RED.withAlpha(0.2))),
          material: Cesium.Color.fromCssColorString(item.color || 'red').withAlpha(0.3),
          outline: true,
          // outlineColor: item.typeId == 2 ? Cesium.Color.GREEN : (item.typeId == 4 ? Cesium.Color.GREEN : (item.typeId == 3 ? Cesium.Color.YELLOW : Cesium.Color.RED)),
          outlineColor: Cesium.Color.fromCssColorString(item.color || 'red'),
          //   classificationType: Cesium.ClassificationType.TERRAIN
          zIndex: item.tier
        },
        show: true,
        id: JSON.stringify(item),
        name: 'area'
      })
      not_fly_area_entities.push(not_fly_entitie)
    }
    if (regionType == "POLYGON") {

      // console.log('this.$route.path', this.$route);
      
      if(this.$route.path != '/grids'){
        not_fly_entitie = viewer.entities.add({
          polygon: {
            hierarchy: {
              positions: Cesium.Cartesian3.fromDegreesArray(positions),
            },
            height: 8, // 给禁飞区添加一个默认的高度，让禁飞区在网格码上面
            outline: true,
            // outlineColor: item.typeId == 2 ? Cesium.Color.GREEN : (item.typeId == 4 ? Cesium.Color.GREEN : (item.typeId == 3 ? Cesium.Color.YELLOW : Cesium.Color.RED)),
            outlineColor: Cesium.Color.fromCssColorString(item.color || 'red'),
            outlineWidth: 2,
            // material: item.typeId == 2 ? Cesium.Color.GREEN.withAlpha(0.2) : (item.typeId == 4 ? Cesium.Color.GREEN.withAlpha(0.7) : (item.typeId == 3 ? Cesium.Color.YELLOW.withAlpha(0.2) : Cesium.Color.RED.withAlpha(0.2))),
            material: Cesium.Color.fromCssColorString(item.color || 'red').withAlpha(0.3),
            // material: Cesium.Color.fromCssColorString(item.color || 'red').withAlpha(1.0),
            zIndex: item.tier
            //   classificationType: Cesium.ClassificationType.TERRAIN
          },
          show: true,
          id: JSON.stringify(item),
          name: 'area'
        })
        not_fly_area_entities.push(not_fly_entitie)
        POLYGONArr.push(not_fly_entitie)
      }
      
    }
    if (regionType == "LINE") {
      not_fly_entitie = viewer.entities.add({
        polyline: {
          positions: Cesium.Cartesian3.fromDegreesArray(positions),
          height: 8, // 给禁飞区添加一个默认的高度，让禁飞区在网格码上面
          width: 3,
          material: Cesium.Color.fromCssColorString(item.color || '#ED7D31').withAlpha(0.3),
          zIndex: item.tier
        },
        show: true,
        id: JSON.stringify(item),
        name: 'line'
      })
      not_fly_area_entities.push(not_fly_entitie)
      POLYGONArr.push(not_fly_entitie)
    }
    if (regionType == "POINT") {
      let p = Cesium.Cartesian3.fromDegreesArray(positions);
      not_fly_entitie = viewer.entities.add({
        position: p[0],
        point: {
          height: 8, // 给禁飞区添加一个默认的高度，让禁飞区在网格码上面
          pixelSize: 15,
          color: Cesium.Color.fromCssColorString(item.color || '#ED7D31').withAlpha(0.8),
          zIndex: item.tier,
          show: true,
        },
        // billboard: {
        //   show: true,
        //   image: require('@/assets/zong_lan_ye_img/home_pt_location.png'),
        //   pixelOffset: new Cesium.Cartesian2(0, 0),
        //   width: 31,
        //   height: 40,
        //   scale: 0.7,
        //   translucencyByDistance: new Cesium.NearFarScalar(1000, 1, 10000000, 0),
        //   disableDepthTestDistance: Number.POSITIVE_INFINITY,
        //   heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        //   disableDepthTestDistance: 99000000,
        //   zIndex: item.tier,
        // },
        // label: {
        //   text: item.name||'xxxxxx',
        //   show: true,
        //   showBackground: false,
        //   font: '14px monospace bolder',
        //   horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        //   verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        //   pixelOffset: new Cesium.Cartesian2(0, -15),
        //   disableDepthTestDistance: Number.POSITIVE_INFINITY,
        //   heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        //   disableDepthTestDistance: 99000000,
        //   fillColor: Cesium.Color.fromCssColorString('#00F5FF'), // 字体颜色
        //   translucencyByDistance: new Cesium.NearFarScalar(1000, 1, 100000, 0),
        //   zIndex: item.tier,
        // },
        show: true,
        id: JSON.stringify(item),
        name: 'point',

      })
      not_fly_area_entities.push(not_fly_entitie)
      POLYGONArr.push(not_fly_entitie)
    }
  },

  change (data, list) {
    console.log('change111', '111');
    // list = tree2list(list) || []
    let is_hide = data.children.every(item => !item.checked)
    if (is_hide) {
      this.factor_hide(data, list)
    } else {
      this.factor_show(data, list)
    }

    // if (data.checked) {
    //     this.factor_show(data, list)
    // } else {
    //     this.factor_hide(data, list)
    // }
  },

  init_collection (cate) {
    console.log(cate, '......cate');
    // let viewer = this.cesium_layer.viewer()
    let viewer = window.viewer
    let scene = viewer.scene
    if (Cesium.defined(this.collection[cate])) {
      scene.primitives.remove(this.collection[cate])
    }
    switch (cate) {
      case 'zysb':
        this.collection[cate] = new Cesium.BillboardCollection({
          scene,
          show: true
        })
        return this.collection[cate]
      case 'jwys':
        this.collection[cate] = new Cesium.BillboardCollection({
          scene,
          show: true
        })
        return this.collection[cate]
      case 'gzsb':
        this.collection[cate] = new Cesium.BillboardCollection({
          scene,
          show: true
        })
        return this.collection[cate]
      case 'jlys':
        this.collection[cate] = new Cesium.BillboardCollection({
          scene,
          show: true
        })
        return this.collection[cate]
      case 'kfq':
        return null
      case 'zddq':
        this.collection[cate] = new Cesium.BillboardCollection({
          scene,
          show: true
        })
        return this.collection[cate]
      case 'dlt':
        this.collection[cate] = new Cesium.BillboardCollection({
          scene,
          show: true
        })
        return this.collection[cate]
    }
  },

  show_collection (cate) {
    let primitive = this.collection[cate]
    if (Cesium.defined(primitive)) {
      let viewer = this.cesium_layer.viewer()
      let scene = viewer.scene
      if (!scene.primitives.contains(primitive)) {
        scene.primitives.add(primitive)
      }
      primitive.show = true
    }
  },
  hide_collection (cate) {
    let primitive = this.collection[cate]
    if (Cesium.defined(primitive)) {
      // let viewer = this.cesium_layer.viewer()
      let viewer = window.viewer
      let scene = viewer.scene
      if (scene.primitives.contains(primitive)) {
        // primitive.show = false
        scene.primitives.remove(primitive)
      }
    }
  },

  async show (data, list, num) {
    // let viewer = this.cesium_layer.viewer()
    let viewer = window.viewer
    viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(120.149356, 33.379614, 100000.0),
    })
    let res
    let cate
    if (num) {
      cate = 1
      res = await API.FACTOR.List({
        cate: 1,
        id: [1, 2, 3, 4, 5]
      })
    } else {
      let root = findRoot(data, list)
      console.log('--------root', root);
      cate = root.cate_id
      res = await API.FACTOR.List({
        cate,
        id: root.children.filter(item => item.checked).map(item => item.id)
      })
    }

    switch (cate) {
      case 1:
        this.factor_add_zysb(res);
        break;
      case 2:
        this.factor_add_jwys(res);
        break;
      case 3:
        this.factor_add_gzsb(res);
        break;
      case 4:
        this.factor_add_jlys(res)
        break;
      case 5:
        this.factor_add_kfq(res);
        break;
      case 6:
        this.factor_add_zddq(res);
        break;
    }
  },

  add_zysb (res) {
    let cate_name = "zysb"
    let collection = this.factor_init_collection(cate_name)
    if (res) {
      let list = []
      res.forEach(item => {
        list.push(...item.firstList)
        list.push(...item.secondList)
        list.push(...item.thirdList)
        list.push(...item.fourthList)
        list.push(...item.fifthList)
      })
      list.forEach(item => {
        item.longitude *= 1
        item.latitude *= 1
        if (item.latitude && item.longitude) {
          collection.add({
            position: Utils.transformWGS842Cartesian({
              lng: item.longitude,
              lat: item.latitude,
              alt: 0
            }),
            // image: item.category === 4 ? require(`@/assets/images/factor/${cate_name}/${item.category}.png`) :  require(`@/assets/images/factor/${cate_name}/${item.category}${(item.category === 1 && item.status == 0) ? '-disable' : ''}.svg`),
            image: require(`@/assets/images/factor/${cate_name}/${item.category * (item.status == 0 ? 100 : 1)}.png`),
            scale: 1,
            width: 54,
            height: 89,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            id: JSON.stringify({
              ...item,
              entityType: 'zdys'
            }),
            distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 2.2e5),
            scaleByDistance: new Cesium.NearFarScalar(2.5e4, 0.7, 2.2e7, 0),
            disableDepthTestDistance: Number.POSITIVE_INFINITY
          })
        }
      })

      !this.cesium_layer.is_plate_mode() && this.factor_show_collection(cate_name)
    }
  },

  add_jwys (res) {
    let cate_name = "jwys"
    let collection = this.factor_init_collection(cate_name)

    let list = []
    res.forEach(item => {
      list.push(...item.firstList)
      list.push(...item.secondList)
      list.push(...item.thirdList)
      list.push(...item.fourthList)
    })

    list.forEach(item => {
      item.longitude *= 1
      item.latitude *= 1
      if (item.latitude && item.longitude) {
        collection.add({
          position: Utils.transformWGS842Cartesian({
            lng: item.longitude,
            lat: item.latitude,
            alt: item.altitude
          }),
          image: require(`@/assets/images/factor/${cate_name}/${item.category}.png`),
          scale: 0.8,
          width: 54,
          height: 89,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          id: JSON.stringify({
            ...item,
            entityType: 'zdys'
          }),
          distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 2.2e5),
          scaleByDistance: new Cesium.NearFarScalar(2.5e4, 0.7, 2.2e7, 0),
          disableDepthTestDistance: Number.POSITIVE_INFINITY
        })
      }
    })

    !this.cesium_layer.is_plate_mode() && this.factor_show_collection(cate_name)
  },

  add_gzsb (res) {
    let cate_name = "gzsb"
    let collection = this.factor_init_collection(cate_name)

    //let list = gzsbJson.RECORDS  //res || []
    let list = res || []
    // res.forEach(item => {
    //     list.push(...(item.bodyWornCameraTests || []))
    //     list.push(...(item.carCamerasTests || []))
    //     list.push(...(item.keyPlaceList || []))
    //     list.push(...(item.policeStationVehicleTests || []))
    //     list.push(...(item.swatVehiclesOnDutyTests || []))
    //     list.push(...(item.trafficPoliceVehiclesOnDutyTests || []))
    // })

    list = list.filter(item => item.longitude && item.latitude)

    list.forEach(item => {
      item.longitude *= 1
      item.latitude *= 1
      if (item.latitude && item.longitude) {
        collection.add({
          position: Utils.transformWGS842Cartesian({
            lng: item.longitude,
            lat: item.latitude,
            alt: item.altitude || 0
          }),
          image: require(`@/assets/images/factor/${cate_name}/${item.category}.png`),
          scale: 0.8,
          width: 54,
          height: 89,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          id: JSON.stringify({
            ...item,
            entityType: 'zdys'
          }),
          distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 2.2e5),
          scaleByDistance: new Cesium.NearFarScalar(2.5e4, 0.7, 2.2e7, 0),
          disableDepthTestDistance: Number.POSITIVE_INFINITY
        })
      }
    })

    !this.cesium_layer.is_plate_mode() && this.factor_show_collection(cate_name)
  },

  add_jlys (res) {

    let cate_name = "jlys"
    let collection = this.factor_init_collection(cate_name)

    let list = res || []
    // Object.keys(res).forEach(key => {
    //     list.push(...(res[key] ? res[key] : []))
    // })

    list.forEach(item => {

      item.longitude *= 1
      item.latitude *= 1
      if (item.latitude && item.longitude) {
        collection.add({
          position: Utils.transformWGS842Cartesian({
            lng: item.longitude,
            lat: item.latitude,
            alt: item.altitude
          }),
          image: require(`@/assets/images/factor/${cate_name}/${item.category}.png`),
          scale: 0.8,
          width: 54,
          height: 89,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          id: JSON.stringify({
            ...item,
            entityType: 'zdys'
          }),
          distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 2.2e5),
          scaleByDistance: new Cesium.NearFarScalar(2.5e4, 0.7, 2.2e7, 0),
          disableDepthTestDistance: Number.POSITIVE_INFINITY
        })
      }
    })

    !this.cesium_layer.is_plate_mode() && this.factor_show_collection(cate_name)
  },

  add_kfq (res) {

    let list = res
    if (!list || !list.length) {
      return list
    }

    let cate_name = "kfq"
    let features = list
    let instances = [];
    for (let i = 0; i < features.length; i++) {
      for (let j = 0; j < features[i].geometry.coordinates.length; j++) {
        let polygonArr = features[i].geometry.coordinates[j].reduce((total, item) => {
          total.push(...item)
          return total
        }, []);

        let polygon = new Cesium.PolygonGeometry({
          polygonHierarchy: new Cesium.PolygonHierarchy(
            Cesium.Cartesian3.fromDegreesArray(polygonArr)
          ),
          vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
        });

        let geometry = Cesium.PolygonGeometry.createGeometry(polygon);

        if (geometry) {
          let colors = {
            '1分钟': '#d42626',
            '3分钟': '#48a1fa',
            '5分钟': '#09f761'
          }
          instances.push(new Cesium.GeometryInstance({
            geometry: geometry,
            attributes: {
              color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromCssColorString(colors[features[i].bs]).withAlpha(0.7)),
            },
            id: JSON.stringify({
              ...features[i],
              entityType: 'zdys'
            }),
          }));
        }
      }
    }
    this.collection[cate_name] = new Cesium.Primitive({
      geometryInstances: instances,
      appearance: new Cesium.PerInstanceColorAppearance({ // 为每个instance着色
        translucent: true,
        closed: false
      }),
      asynchronous: false,
      show: true
    });

    !this.cesium_layer.is_plate_mode() && this.factor_show_collection(cate_name)
  },

  add_zddq (res) {
    let cate_name = "zddq"
    let list = res.reduce((arrs, item) => {
      arrs.push(...item.keyPlaceList)
      return arrs
    }, [])

    let collection = this.factor_init_collection(cate_name)
    list.forEach(item => {
      item.longitude *= 1
      item.latitude *= 1
      if (item.latitude && item.longitude) {
        collection.add({
          position: Utils.transformWGS842Cartesian({
            lng: item.longitude,
            lat: item.latitude,
            alt: item.altitude
          }),
          image: require(`@/assets/images/factor/${cate_name}/${item.category}.png`),
          scale: 0.8,
          width: 54,
          height: 89,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          id: JSON.stringify({
            ...item,
            entityType: 'zdys'
          }),
          distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 2.2e5),
          scaleByDistance: new Cesium.NearFarScalar(2.5e4, 0.7, 2.2e7, 0),
          disableDepthTestDistance: Number.POSITIVE_INFINITY
        })
      }
    })

    !this.cesium_layer.is_plate_mode() && this.factor_show_collection(cate_name)
  },
  add_dlt (res) {
    let cate_name = "dlt"
    let list = res.reduce((arrs, item) => {
      arrs.push(...item.keyPlaceList)
      return arrs
    }, [])

    let collection = this.factor_init_collection(cate_name)
    list.forEach(item => {
      item.longitude *= 1
      item.latitude *= 1
      if (item.latitude && item.longitude) {
        collection.add({
          position: Utils.transformWGS842Cartesian({
            lng: item.longitude,
            lat: item.latitude,
            alt: item.altitude
          }),
          image: require(`@/assets/images/factor/${cate_name}/dlt1.png`),
          scale: 0.8,
          width: 54,
          height: 89,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          id: JSON.stringify({
            ...item,
            entityType: 'dlt'
          }),
          distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 2.2e5),
          scaleByDistance: new Cesium.NearFarScalar(2.5e4, 0.7, 2.2e7, 0),
          disableDepthTestDistance: Number.POSITIVE_INFINITY
        })
      }
    })

    !this.cesium_layer.is_plate_mode() && this.factor_show_collection(cate_name)
  },
  hide (data, list) {
    let root = findRoot(data, list)
    let cate = root.cate_id

    switch (cate) {
      case 1:
        this.factor_hide_collection("zysb")
        break;
      case 2:
        this.factor_hide_collection("jwys")
        break;
      case 3:
        this.factor_hide_collection("gzsb")
        break;
      case 4:
        this.factor_hide_collection("jlys")
        break;
      case 5:
        this.factor_hide_collection("kfq")
        break;
      case 6:
        this.factor_hide_collection("zddq")
        break;
    }
  },
  init_ws () {
    let ws_url_al = process.env.VUE_APP_WS_URL_ALARM
    let ws = new WebSocket(ws_url_al);
    let _this = this
    let { appid, username } = JSON.parse(localStorage.getItem("user_info")).data;
    let token = JSON.parse(localStorage.getItem("user_info")).data["mmc-identity"]
    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          type: 100,
          systemCode: "mmc",
          state: 1,
          username,
          token,
          appId: appid,
        })
      );

    };

    ws.onmessage = (e) => {
      let metadata = JSON.parse(e.data)
      // console.log(metadata, '无人机原始数据，可能引起地图压力过大17');
      if (metadata.msgnum === 4116) {
        _this.isAlarm = true
        _this.isHint = true
      }
      if (metadata.msgnum === 4117) {
        _this.isAlarm = false
      }


    };

    ws.onerror = (error) => {
      //console.log("error", error);
    };

    ws.onclose = (data) => {
      console.log("onclose", data);
    };
  },


}