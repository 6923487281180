<template>
  <div class="cpt-hwkeys cp">

    <el-tooltip class="item" effect="dark" content="二三维" placement="top">
      <div class="change">
        <div class="button" :class="{ active: !is_3d, color: routeColor == true }" v-show="!is_3d" @click="handle_change_3d(true)">
          2D
        </div>
        <div class="button" :class="{ active: is_3d, color: routeColor == true }" v-show="is_3d" @click="handle_change_3d(false)">
          3D
        </div>
      </div>
    </el-tooltip>
    <div class="hwkeys-wrap" :class="{ active, color: routeColor == true }" @click="change">
      <div :class="{ 'hwkeys-item': 1, [item.class_name]: 1, }" v-for="item in tabs" :key="item.id" v-show="active || item.id == current"
        @click="handle_change(item.id)">
        <div class="typeNmae" :class="{ color: routeColor == true }">
          {{ item.name }}
        </div>
      </div>
    </div>
    <!-- 网格码列表 -->
    <div class="hwkeys-wrap-grids" :class="{ active_grids, color: routeColor == true }" @click="change_grids" @mouseover="active_grids = true"
      @mouseout="active_grids = false">
      <div :class="{ 'hwkeys-item': 1, [item.class_name]: 1, }" v-for="item in grids_list" :key="item.index"
        v-show="active_grids || item.index == current_grids" @click="handle_grids_change(item)">
        <div class="typeNmae" :class="{ color: routeColor == true }">
          <!-- {{ item.name }} -->
        </div>
      </div>
    </div>
    <!-- 添加地图快速定位功能 -->
    <!-- <div
      class="hwkeys-wrap-postion"
      :class="{ mapLocationActive }"
      @click="mapLocationChange"
    >
      <div
        :class="{
          'hwkeys-item': 1,
          [item.class_name]: 1,
        }"
        v-for="item in mapLocation"
        :key="item.id"
        v-show="mapLocationActive || item.id == mapLocationSelectId"
        @click="mapLocationItemClick(item)"
      >
        <div class="typeNmae">{{ item.name }}</div>
      </div>
    </div> -->
    <div v-show="waterFlag" @click="openwater" class="iconfont icon-shuizhijiance waterbox"></div>
    <div class="tubiao">
      <div class="kuang" v-for="(item, index) in icon" :key="index" :title="item.title" @click="pop_up(item.title)">
        <i class="iconfont" :class="item.icon"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { global_leyers, base_layer } from "@/utils/global";
import geoJsonZHZ from "@/data/geo/geo_zhz.json";
import Water from "@/components/accident/water";
import EventBus from "@/assets/ligature.js";
export default {
  components: { Water },
  data() {
    return {
      waterFlag: false,
      showwater: false,
      active: false,
      flag: false,
      is_3d: true,
      command: false,
      routeColor: false,
      current: 1,
      tabs: [
        {
          id: 1,
          name: "卫星",
          class_name: "hwkeys-item-satellite",
        },
        {
          id: 2,
          name: "街道",
          class_name: "hwkeys-item-street",
        },
        {
          id: 3,
          name: "夜景",
          class_name: "hwkeys-item-night-blue",
        },
      ],
      // 网格码列表
      grids_list: [],
      mapLocationActive: false,
      mapLocationSelectId: 1,
      mapLocation: [
        {
          id: 1,
          name: "北京",
          class_name: "hwkeys-item-beijing",
          pt: [116.550047, 39.777888, 21812.321476599747],
        },
        {
          id: 2,
          name: "深圳",
          class_name: "hwkeys-item-shenzhen",
          pt: [114.241663, 22.659995, 21812.321476599747],
        },
        {
          id: 3,
          name: "敦煌",
          class_name: "hwkeys-item-donghuang",
          pt: [94.671978, 40.14941],
        },
        {
          id: 4,
          name: "合肥",
          class_name: "hwkeys-item-hefei",
          pt: [117.955457, 31.648422, 21812.321476599747],
        },
        {
          id: 5,
          name: "西安",
          class_name: "hwkeys-item-xian",
          pt: [108.839609, 34.216241, 21812.321476599747],
        },
        {
          id: 6,
          name: "芜湖",
          class_name: "hwkeys-item-wuhu",
          pt: [118.645826, 31.133454, 21812.321476599747],
        },
      ],
      icon: [],
      active_grids: false,
      current_grids: 1,
    }
  },
  created() {
    if (this.$route.path == "/command") {
      this.command = true;
    } else {
      this.command = false;
    }
    console.log("handle_change1");
    // this.handle_change(3);
    let mapIndex = 1;
    if (this.$route.path === "/home") {
      //首页默认加载深色地图
      mapIndex = 3;
    }
    setTimeout(() => {
      this.handle_change(mapIndex);
      this.handle_change_3d(false);
    }, 3500);
    EventBus.$on("icons", (icon, title) => {
      let a = { icon, title };
      this.icon.push(a);
    });
    EventBus.$on("deletes", (icons) => {
      let a = this.icon;
      a = JSON.parse(JSON.stringify(a));
      let index = a.findIndex(function (item) {
        return item.icon === icons;
      });
      a.splice(index, 1);
      this.icon = a;
    });
  },
  mounted() {
    // console.log('grids_list', grids_list);
    // 全局变量，index.html引入
    this.grids_list = grids_list;
  },
  watch: {
    "$store.state.device.mapFlag"(newData) {
      this.flag = newData;
    },
    "$store.state.device.waterFlag"(newData) {
      this.waterFlag = newData;
    },
    "$route.path": {
      handler(value) {
        if (value == "/home1027") {
          this.routeColor = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    pop_up(item) {
      EventBus.$emit("CloseMenu");
      EventBus.$emit("icon_message", item);
    },
    guanbiwater() {
      this.showwater = false;
    },
    openwater() {
      // this.showwater = true
      // "$store.state.device.mapFlag"(newData){
      //   this.flag = newData
      // }
      this.$store.commit("device/SET_WATERBOX", true);
    },
    handle_change_3d(bool) {
      this.is_3d = bool;
      this.$store.commit("device/SET_3D_STATUS", bool);
      // try {
      //   global_leyers.forEach((promise) => {
      //     promise.then((layer) => {
      //       layer.visible = bool;
      //     });
      //   });
      // } catch (error) { }
      if (bool) {//二维
        window.viewer.scene.morphTo2D(0);
      } else {
        window.viewer.scene.morphTo3D(0);
      }
    },
    handle_change(id) {
      this.current = id;
      if (id == 1) {
        base_layer.BASIC && (base_layer.BASIC.show = false);
        base_layer.SATELLITE && (base_layer.SATELLITE.show = true);
        base_layer.NIGHT && (base_layer.NIGHT.show = false);
        base_layer.TDTYXZJ && (base_layer.TDTYXZJ.show = true);
      } else if (id == 2) {
        base_layer.BASIC && (base_layer.BASIC.show = true);
        base_layer.SATELLITE && (base_layer.SATELLITE.show = false);
        base_layer.NIGHT && (base_layer.NIGHT.show = false);
        base_layer.TDTYXZJ && (base_layer.TDTYXZJ.show = false);
      } else {
        base_layer.BASIC && (base_layer.BASIC.show = false);
        base_layer.SATELLITE && (base_layer.SATELLITE.show = false);
        base_layer.NIGHT && (base_layer.NIGHT.show = true);
        base_layer.TDTYXZJ && (base_layer.TDTYXZJ.show = false);
      }
      console.log("base_layer", base_layer, id);
      this.mapLocationed();
    },
    change() {
      this.active = !this.active;
      // global_leyers.forEach(promise => {
      //   promise.then((layer) =>{
      //     layer.visible = this.active;
      //   });
      // })
      // base_layer.BASIC && (base_layer.BASIC.show = !this.active)
      // base_layer.SATELLITE && (base_layer.SATELLITE.show = this.active)
    },
    //增加地图快速定位-视图切换
    mapLocationChange() {
      this.mapLocationActive = !this.mapLocationActive;
    },
    mapLocationItemClick(item) {
      if (!this.mapLocationActive) return;
      this.mapLocationSelectId = item.id;
      //定位到对应的地图位置
      let point = item.pt;
      window.viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          point[0],
          point[1],
          point[2]
        ),
        orientation: {
          heading: 0.006918732929549698,
          pitch: -0.6928665494487145,
          roll: 7.638334409421077e-14,
        },
        time: 5,
      });
    },
    /**@Description :  ******************************************* 网格码点击事件
     **@Date: 2024-04-10 09:37:24
    */
    handle_grids_change(item) {
      this.current_grids = item.index;
      console.log('网格码点击事件', item);
      EventBus.$emit('girds_ids', item);
    },
    /**@Description :  ******************************************* 点击网格
     **@Date: 2024-04-10 09:45:57
    */
    change_grids() {
      this.active_grids = !this.active_grids;
    },
    /**@Description :  ******************************************* 定位到十堰市
         **@Date: 2024-06-06 15:50:19
        */
    mapLocationed() {
      // 默认芜湖坐标
      let lon = 118.22073, lat = 29.50576, height = 151812.321476599747;
      // 读取配置文件里面的坐标
      let sceneConfigData = window.localStorage.getItem('sceneConfigData');
      sceneConfigData = JSON.parse(sceneConfigData);
      // console.log('sceneConfigDatasceneConfigDatasceneConfigDatasceneConfigDatasceneConfigData', sceneConfigData);
      if (sceneConfigData && sceneConfigData['home_title'] && sceneConfigData['home_title'].indexOf('十堰市') != -1) {
        let location = sceneConfigData['location'];
        lon = location.lon;
        lat = location.lat;
        height = location.height;
      }
      if (this.$store.state.user.user_info.username == "dongtai") {
        lon = 120.60221873;
        lat = 31.08398637;
        height = 151812.321476599747;
        // console.log('定位到东台');
      }
      // 首页初始化定位芜湖市！！！
      console.log('首页初始化定位芜湖市！！！');

      // console.log('window.viewer', window.viewer);
      window.viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(lon, lat, height),
        orientation: {
          heading: 0.006918732929549698,
          pitch: -0.6928665494487145,
          roll: 7.638334409421077e-14
        },
        duration: 3,
        complete: () => {
          EventBus.$emit("locationDone");
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.cpt-hwkeys {
  position: absolute;
  // display: none;
  right: 0;
  top: 144px;

  width: 104px;
  height: 78px;
  transition: 0.2s;
  transform: translateX(0);

  &.wkeysRight {
    transition: 0.3s;
    transform: translateX(-12px);
    right: 40px;
  }

  .change {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;

    .button {
      // padding: 0 5px;
      height: 48px;
      width: 48px;
      // border: 1px solid #FFF;
      // box-shadow: inset 0 0 7px 0 #008fff;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      font-size: 20px;
      font-weight: 400;
      line-height: 50px;
      text-align: center;
      color: #fff;
      box-sizing: border-box;
      background: rgba(35, 47, 79, 0.75);
      opacity: 0.7;

      // background: rgba(0,23,79,0.70);
      &:hover {
        background: rgba(22, 29, 45, 0.7);
        border: 1px solid #43ddfe;
        color: #43ddfe;
      }

      &:active {
        background: rgba(22, 29, 45, 0.8);
      }

      // &.active {
      //   box-shadow: inset 0 0 7px 0 #43ddfe;
      // }
    }
  }

  .hwkeys-wrap {
    position: absolute;
    top: 55px;
    right: 0;
    width: 48px;
    height: 48px;
    // background: rgba(22, 29, 45, 0.58);
    border-radius: 2px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 0.25s;

    &.active {
      width: 166px;
      // border: 1px solid #43deff;
    }

    .hwkeys-item {
      width: 48px;
      height: 48px;
      // border: 1px solid #43ddfe;
      position: relative;
      border-radius: 4px;

      &:hover {
        border: 1px solid #43ddfe;
      }

      &.hwkeys-item-satellite {
        background: url(../../assets/images/3d.png) center no-repeat;
      }

      &.hwkeys-item-street {
        background: url(../../assets/images/2d.png) center no-repeat;
      }

      &.hwkeys-item-night-blue {
        background: url(../../assets/images/night-blue.png) center no-repeat;
        // background-size: 115% 115%;
      }

      &.hwkeys-item-3d {
        background: url(../../assets/images/3d.png) center no-repeat;
      }

      &.hwkeys-item-2d {
        background: url(../../assets/images/2d.png) center no-repeat;
      }

      &.hwkeys-item-street {}

      .typeNmae {
        position: absolute;
        bottom: 0;
        right: 0;
        // width: 20px;
        z-index: 1;
        height: 20px;
        // border: 1px solid #43ddfe;
        // box-shadow: inset 0 0 7px 0 #333;
        background: rgba($color: #000000, $alpha: 0.5);
        border-radius: 1px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #fff;
      }
    }
  }

  .hwkeys-wrap-grids {
    position: absolute;
    top: 110px;
    right: 0;
    width: 48px;
    height: 48px;
    // background: rgba(22, 29, 45, 0.58);
    border-radius: 2px;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: width 0.25s;
    background-color: #11788A;
    // border:1px solid #ff0000;
    border-radius: 8px;
    // margin-right: 15px;

    &.active {
      width: 166px;
      // border: 1px solid #43deff;
    }

    &.active_grids {
      width: 200px;
      // border: 1px solid #43deff;
    }

    .hwkeys-item {
      width: 48px;
      height: 48px;
      // border: 1px solid #43ddfe;
      position: relative;
      border-radius: 4px;

      &:hover {
        border: 1px solid #43ddfe;
      }

      &.hwkeys-item-grids-1 {
        background: url(../../assets/img/24gl-grid.png) center no-repeat;
        background-size: 100% 100%;
      }

      &.hwkeys-item-grids-wh {
        background: url(../../assets/img/wh.png) center no-repeat;
        background-size: 100% 100%;
        // margin-right: 3px;
      }

      &.hwkeys-item-grids-zz {
        // background: url(../../assets/img/zz.png) center no-repeat;
        background: url(../../assets/img/dt.png) center no-repeat;
        background-size: 100% 100%;
        // margin-right: 3px;
      }

      &.hwkeys-item-grids-sz {
        background: url(../../assets/img/sz.png) center no-repeat;
        background-size: 100% 100%;
        // margin-right: 3px;
      }

      &.hwkeys-item-grids-sy {
        background: url(../../assets/img/sy.png) center no-repeat;
        background-size: 100% 100%;
        // margin-right: 3px;
      }

      &.hwkeys-item-satellite {
        background: url(../../assets/images/3d.png) center no-repeat;
        background-size: 100% 100%;
      }

      &.hwkeys-item-street {
        background: url(../../assets/images/2d.png) center no-repeat;
      }

      &.hwkeys-item-night-blue {
        background: url(../../assets/images/night-blue.png) center no-repeat;
        // background-size: 115% 115%;
      }

      &.hwkeys-item-3d {
        background: url(../../assets/images/3d.png) center no-repeat;
      }

      &.hwkeys-item-2d {
        background: url(../../assets/images/2d.png) center no-repeat;
      }

      &.hwkeys-item-street {}

      .typeNmae {
        position: absolute;
        bottom: 0;
        right: 0;
        // width: 20px;
        z-index: 1;
        height: 20px;
        // border: 1px solid #43ddfe;
        // box-shadow: inset 0 0 7px 0 #333;
        background: rgba($color: #000000, $alpha: 0.5);
        border-radius: 1px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #fff;
      }
    }
  }

  // 增加地图定位 add 2023/5/23 by txc
  .hwkeys-wrap-postion {
    position: absolute;
    top: 112px;
    right: 0;
    width: 50px;
    height: 50px;
    background: rgba(22, 29, 45, 0.58);
    border: 1px solid #43ddfe;
    border-radius: 2px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 0.25s;

    &.mapLocationActive {
      width: 516px;
    }

    .hwkeys-item {
      width: 84px;
      height: 62px;
      border: 1px solid #43ddfe;
      position: relative;

      &.hwkeys-item-beijing {
        background: url(../../assets/zong_lan_ye_img/zhz_home_beijing.png) center no-repeat;
      }

      &.hwkeys-item-donghuang {
        background: url(../../assets/zong_lan_ye_img/zhz_home_donghuang.jpg) center no-repeat;
      }

      &.hwkeys-item-shenzhen {
        background: url(../../assets/zong_lan_ye_img/zhz_home_shenzhen.png) center no-repeat;
        // background-size: 115% 115%;
      }

      &.hwkeys-item-xian {
        background: url(../../assets/zong_lan_ye_img/zhz_home_xi_an.png) center no-repeat;
      }

      &.hwkeys-item-hefei {
        background: url(../../assets/zong_lan_ye_img/zhz_home_hefei.png) center no-repeat;
      }

      &.hwkeys-item-wuhu {
        background: url(../../assets/zong_lan_ye_img/zhz_home_wuhu.jpg) center no-repeat;
      }

      .typeNmae {
        position: absolute;
        bottom: 0;
        right: 0;
        // width: 20px;
        z-index: 1;
        height: 20px;
        border: 1px solid #43ddfe;
        box-shadow: inset 0 0 7px 0 #43ddfe;
        border-radius: 1px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #04d5da;
      }
    }
  }

  .waterbox {
    height: 48px;
    width: 48px;
    position: absolute;
    top: 130px;
    right: 0px;
    border: 1px solid #04d5da;
    background: rgba(22, 29, 45, 0.7);
    color: #43deff;
    font-size: 20px;
    line-height: 48px;
  }

  .water_quality {
    width: 459px;
    height: 418px;
    background: rgba(0, 23, 79, 0.7);
    box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
      inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
    border-radius: 13px;
  }
}

.tubiao {
  position: absolute;
  top: 110px;
  right: 0;
  display: flex;
  border: 1px solid #43deff;
  border-right: 0.5px solid #43deff;
  border-left: 0.5px solid #43deff;
  // transform: rotate(-90deg);
  background: rgba(0, 23, 79, 0.7);
}

.kuang {
  height: 50px;
  width: 50px;
  border-right: 0.5px solid #43deff;
  border-left: 0.5px solid #43deff;
  font-family: MicrosoftYaHeiUI;
  color: #43deff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  line-height: 50px;

  .iconfont {
    font-size: 30px;
  }
}

.color {
  // border: 1px solid #1772c1 !important;
  // color:#1772c1 !important ;
}
</style>
