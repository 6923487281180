<template>
  <!--  -->
  <div v-show="newsList.length && showNotice > 0" class="pr notifications h40  pf mt190 " @mouseover="showcolse = true"
    @mouseout="showcolse = false">
    <div class="  mt10  top0 cfc h30 dib icon-xuanchuan1 iconfont " alt="" />
    <div class="w650 h40 pa ofh top0 ml25 mr50">
      <!-- ref="cmdlist" js写的动画速度不均匀 -->
      <div class="ml20 inner" id="pWidth">
        <div class="dib mr40 " v-for="(item, index) in newsList" :key="index" @click="handleClick(item)">
          {{ item.newsContent }}
        </div>
      </div>
    </div>

    <div @click="close" class="close cfc ml10">
      <span class="icon-guanbi iconfont"></span>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  props: {
    messagenum: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showNotice: false,
      showcolse: true,
      newsList: [],
      loopTime: null,
      timer: null,
      value: 0,
      timer: "", //计时器
      pwidth: 0, //公告文本的宽度
      windowWidth: 700, // 设备屏幕的宽度
      ws: ""
    };
  },
  mounted() {

    let box1 = document.getElementById("pWidth");
    let width = getComputedStyle(box1, null).getPropertyValue("width");

    box1.style.setProperty('--width', width === 'auto' ? '-700px' : '-' + width)
    console.log(width);
    this.init();
    setTimeout(() => {
      this.showNotice = true;
    }, 1000);
    this.timer = setInterval(this.clickCommend, 3);
  },
  watch: {
    messagenum: {
      handler(val) {
        if (val.includes(4)) {
          this.init();
        }
      }
    },
    value(newValue, oldValue) {
      if (document.getElementById("pWidth")) {
        let allWidth =
          parseInt(this.windowWidth) +
          document.getElementById("pWidth").offsetWidth;
        if (newValue <= -allWidth) {
          this.$refs.cmdlist.style.left = "100%";
          this.value = 0;
        }
      }
    },
  },
  methods: {
    // clickCommend(e) {
    //   let _this = this;
    //   this.$nextTick(() => {
    //     this.value -= 1;
    //     if (this.$refs.cmdlist) {
    //       this.$refs.cmdlist.style.left = _this.windowWidth + this.value + "px";
    //     }
    //   });
    // },
    handleClick(item) {
      console.log('aaa', item);
      this.$emit("messageInfo", item.id || item.waringId);
      // await API.HOME.readingmessage({ newsId: arr.join(",") });
      this.newsList = [];
    },
    async close() {
      let arr = [];
      this.newsList.forEach((e) => {
        arr.push(e.id);
      });
      await API.HOME.readingmessage({ newsId: arr.join(",") });
      await API.HOME.readingyjmessage({});
      this.newsList = [];
    },
    async init() {
      let res = await API.HOME.rollingmessage();
      this.newsList = res || [];
      console.log(this.newsList);
    },
    beforeDestroy() {
      this.ws.close()
      this.ws = ''
      clearInterval(this.timer)
    },
  },
};
</script>

<style lang="scss" scoped>
.seamless-warp {
  height: 40px;
  // pointer-events: none;
}

.notifications {
  width: 700px;
  z-index: 9999;
  border-radius: 15px;
  padding: 0 10px;
  overflow: hidden;
  color: #fff;
  left: 50%;
  background: rgba(6, 53, 111, 0.6);
  box-shadow: inset 2px 2px 5px 0 #0590c7;
  margin-left: -350px;
}

.close {
  position: absolute;
  cursor: pointer;
  padding-top: 10px;
  right: 10px;
  top: 0px;
  z-index: 99;
}

#pWidth {
  z-index: 9 !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  position: absolute;
  /*  作了修改*/
  top: 0;
  /*  作了修改*/
  left: 700px;
  /* 作了修改*/

  line-height: 40px;
  display: block;
  word-break: keep-all;
  // text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 微软雅黑;
  color: rgb(0, 228, 255);
  font-size: 18px;
}

::-webkit-scrollbar {
  display: none;
}

.inner {
  display: flex;
}

li {
  list-style: none;
  height: 40px;
  text-align: center;
  line-height: 40px;
  white-space: nowrap;
  list-style: none;
}

// 动画
.inner {
  // width: fit-content;
  display: inline-block;
  animation: moveAni 5s infinite linear normal;

  a {
    width: fit-content;
  }
}

@keyframes moveAni {
  0% {
    left: 650px;
  }

  100% {
    left: var(--width);
  }
}
</style>