<!-- 总览页cesium -->
<template>
  <div class="cpt-home-cesium_layer">
    <div
      id="layer-container"
      class="bk1027"
      :class="{
        //isFullscreenBool 为true时代表全屏
        ycFlag_01: ycFlag && ycnum == 0 && !isFullscreenBool,
        ycFlag_01_full: ycFlag && ycnum == 0 && isFullscreenBool,
        ycFlag_02: ycFlag && ycnum == 1,
        ycFlag_02_full: ycFlag && ycnum == 1 && isFullscreenBool,
        ycFlag_03: ycFlag && ycnum == 2,
        ycFlag_03_full: ycFlag && ycnum == 2 && isFullscreenBool,
        'ycFlag--collapse': ycMapCollapse,
        wrjFlag,
        wrjFlag1,
        fkVideo,
        yc1027: yPath == true,
        uav1027: uavpath == true,
      }"
    ></div>
    <!-- 动态渲染多个视频弹窗 -->
    <DTVideo
      v-for="(videoPopup, index) in videoPopups"
      :key="index"
      :title="videoPopup.title"
      :videoUrl="videoPopup.videoUrl"
      :fullScreen="videoPopup.fullScreen"
      @closeVideo="closeVideo(index)"
      @fullScreen="toggleFullScreen(index)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Utils } from "@/lib/cesium";
import GeoJson from "@/data/geo/geo_yc.json";
import GeoJsonBorder from "@/data/geo/geo_yc_border.json";
import API from "@/api";
import { primitives_3d_tiles, global_leyers, base_layer } from "@/utils/global";
import { BaiduImageryProvider } from "@/lib/cesium";
import { Message } from "element-ui";
import DTVideo from "@/components/dt-video";
// 引用bus
import Bus from "@/assets/ligature.js";
import wuhuGeoSot17 from "@/data/grid/wuhuGeoSot-20230821151223-17.json";
// 芜湖边界
import WuHuGeoJsonBorder from "@/data/geo/geo_wuhu_border.json";
// 顺德边界
import ShunDeGeoJsonBorder from "@/data/geo/geo_shunde_border.json";
// 十堰市茅箭区边界
import MaoJIanGeoJsonBorder from "@/data/geo/geo_maojian_border.json";
// 十堰市边界
import ShiYanGeoJsonBorder from "@/data/geo/geo_shiyan_border.json";
// 深圳geojson
import ShenZhenGeoJsonPolygon from "@/data/geo/geo_shenzhen_440300_full.json";
//深圳格网数据
import ShenZhenGridJson from "@/data/geo/grid_shenzhen.json";
// 东台起降场
import geoDongtaiPoint from "@/data/geo/geo_dongtai_point.json";
let viewer = null;
// let plate_data_source = null;
let border_data_source = null;
let center = null;
let LAYERS = null;
let envType = process.env.VUE_APP_ENV_TYPE;

let border_data_source_wuhu = null;
let border_data_source_shunde = null;
let border_data_source_shenzhen = null;
let border_data_source_maojian = null;
let border_data_source_shiyan = null;
// console.log("envType", envType);
let WHXFZDIMGURL = process.env.VUE_APP_WHXFZD_URL;
let WZNHYIMGURL = process.env.VUE_APP_WZNHY_URL;
// 芜湖消防支队影像图和标签
let whimgerLayer = null;
let whPoint = null;
let shenzhenLonggangLayer = null;
let shenzhenLonggangLayerUrl = process.env.VUE_APP_SHENZHENLONGGANG_URL;
let shiyanMaojianLayer = null;
let shiyanMaojianLayerUrl = process.env.VUE_APP_SHIYANMAOJIAN_URL;
let wuhuWanzhiLayer = null;
let wuhuWanzhiLayerUrl = process.env.VUE_APP_WUHUWANZHI_URL;
let zhengzhouJinshuiLayer = null;
let zhengzhouJinshuiLayerUrl = process.env.VUE_APP_ZHENGZHOUJINSHUI_URL;
let zhengzhouZhongyuanLayer = null;
let zhengzhouZhongyuanLayerUrl = process.env.VUE_APP_ZHENGZHOUZHONGYUAN_URL;
// 佛山顺德
let foshanShunDeLayer = null;
let foshanShunDeLayerUrl = process.env.VUE_APP_FOSHANSHUNDE_URL;
// 深圳基地
let shenZhenJDDeLayer = null;
// let shenZhenJDLayerUrl = process.env.VUE_APP_shenZhenJD_URL;
let shenZhenJDLayerUrl = process.env.VUE_APP_shenZhenJD_URL;
let shenZhenJDLayer2Url = process.env.VUE_APP_shenZhenJD_2_URL;
let dt_video_url = process.env.VUE_APP_DONGTAI_VIDEO_URL;
switch (envType) {
  case "公网":
    LAYERS = Object.freeze({
      BASIC: {
        image: new Cesium.UrlTemplateImageryProvider({
          url: "https://webrd01.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8",
        }),
      },
      SATELLITE: {
        image: new Cesium.WebMapTileServiceImageryProvider({
          url: "https://{s}.tianditu.gov.cn/img_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE={style}&TILEMATRIXSET={TileMatrixSet}&FORMAT=tiles&TILEMATRIX={TileMatrix}&TILEROW={TileRow}&TILECOL={TileCol}&tk=094247775c509d9f6f2856f8fd660b33",
          layer: "img",
          style: "default",
          format: "tiles",
          tileMatrixSetID: "c",
          credit: new Cesium.Credit("天地图全球影像服务"),
          tilingScheme: new Cesium.GeographicTilingScheme(), //采用经纬度投影
          subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
          tileMatrixLabels: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
          ],
          maximumLevel: 17,
        }),

        // image: new Cesium.UrlTemplateImageryProvider({
        //   url: 'https://webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}',
        //   maximumLevel: 18,
        //   minimumLevel: 1,
        //   credit: 'Amap'
        // }),

        // image: new Cesium.UrlTemplateImageryProvider({
        //   url: "https://webst02.is.autonavi.com/appmaptile?lang=zh_cn&size=10&scale=10&style=8&x={x}&y={y}&z={z}",
        //   layer: "tdtVecBasicLayer",
        //   style: "default",
        //   format: "image/png",
        //   tileMatrixSetID: "GoogleMapsCompatible"
        // })
      },
      // NIGHT: {
      //   image: new Cesium.UrlTemplateImageryProvider({
      //     url: 'https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}'
      //   })
      // }
    });
    break;

  //默认为内网配置
  default:
    LAYERS = Object.freeze({
      BASIC: {
        image: new Cesium.UrlTemplateImageryProvider({
          url: "http://32.128.12.23:58088/minemap-data/data/raster/{sz}/{x}/{y}",
          tilingScheme: new Cesium.GeographicTilingScheme(),
          customTags: {
            sz: function (imageryProvider, x, y, level) {
              return level + 1;
            },
          },
        }),
      },
      SATELLITE: {
        image: new Cesium.UrlTemplateImageryProvider({
          url: "http://32.128.12.23:58090/minemap-data/data/satellite/{sz}/{x}/{y}",
          tilingScheme: new Cesium.GeographicTilingScheme(),
          customTags: {
            sz: function (imageryProvider, x, y, level) {
              return level + 1;
            },
          },
        }),
      },
      NIGHT: {
        image: new Cesium.UrlTemplateImageryProvider({
          url: "http://32.128.12.23:58089/minemap-data/data/raster/{sz}/{x}/{y}",
          tilingScheme: new Cesium.GeographicTilingScheme(),
          customTags: {
            sz: function (imageryProvider, x, y, level) {
              return level + 1;
            },
          },
        }),
      },
    });
}

const MODES = Object.freeze({
  PLATE: Symbol("PLATE"),
  GLOBE: Symbol("GLOBE"),
});

const origin = new Cesium.Cartesian3(
  -2718455.981755302,
  4687924.665227746,
  3547699.7600637083
);

const plate_orign = new Cesium.Cartesian3(
  -2988857.0261368295,
  5044169.936281486,
  3519001.2112579183
);

const plate_hpr = {
  heading: 6.163788972318109,
  pitch: -1.0166554553384142,
  roll: 6.262799873942939,
};

const tinghu2_origin = new Cesium.Cartesian3(
  -2694782.3468037946,
  4621493.91410246,
  3479515.1219724463
);
import worldImg from "@/assets/images/world.jpg";

export default {
  data() {
    return {
      MODES,
      ycnum: null, //  num为0 切换无人机视频 num为1 仓内视频切换  num为2 仓外视频  作用：地图位置
      ycFlag: false,
      wrjFlag: false,
      wrjFlag1: false,
      fkVideo: false,
      initCount: 0, //初始化次数计数器
      yPath: false, // 判断当前页面是旧版还是1027版
      uavpath: false, //飞控机巢新版视频背景模式判断小地图在哪显示
      isFullscreenBool: false,
      uavType: {
        //   'px4': 'cesium/model/x85_0311_4.gltf',//四旋翼
        // 'apm': 'cesium/model/1550_ON_6.gltf',//六旋翼
        // 'amp': 'cesium/model/uav.gltf',//六旋翼
        // 'amp': [
        //   'cesium/model/TD220/body.gltf',
        //   'cesium/model/TD220/TD220B_static_lower_rotar.gltf',
        //   'cesium/model/TD220/TD220B_static_upper_rotar.gltf'
        // ],//TD220

        // 'px4': 'cesium/model/x85_0311_4.gltf',//四旋翼
        // 'apm': 'cesium/model/1550_ON_6.gltf',//六旋翼
        // 'apm': 'cesium/model/Q20/model_01.gltf',//六旋翼 Q20测试
        // 'apm': 'cesium/model/Q20/Q20_polyModel_withAnimation_20230922_08.glb',//Q20测试
        apm: "cesium/model/uav.gltf", //六旋翼
        // 'amp': 'cesium/model/uav.gltf',//六旋翼
        amp1: [
          "cesium/model/TD220/body.gltf",
          "cesium/model/TD220/TD220B_static_lower_rotar.gltf",
          "cesium/model/TD220/TD220B_static_upper_rotar.gltf",
        ], //TD220
        // amp 其实是550 2024年1月4日 17:54:44
        amp: [
          "cesium/model/550/body_meshModel_noMotion_20230830.gltf",
          "cesium/model/550/lower_rotar_meshModel_noMotion_20230830.gltf",
          "cesium/model/550/upper_rotar_meshModel_noMotion_20230830.gltf",
        ],
        px4_temp: [
          "cesium/model/Q20/TA-Q20-wjy-1.glb",
          "cesium/model/Q20/yj.glb",
        ], //Q20是四旋翼
        px4xx: [
          // 'cesium/model/550/body_meshModel_noMotion_20230830.gltf',
          // 'cesium/model/550/lower_rotar_meshModel_noMotion_20230830.gltf',
          // 'cesium/model/550/upper_rotar_meshModel_noMotion_20230830.gltf'
          // 'cesium/model/Q20/TA-Q20-wjy-1.glb',
          // 'cesium/model/Q20/yj.glb'
        ], // 550 模型，暂时px4使用550 ，Q20模型太大影像性能不加载
        // 'px4': ['cesium/model/Q20/whole_model_animation_20230908_01.gltf'],//Q20 是四旋翼
        px4: "cesium/model/Q20/Q20_withAnimation_20230926_01.gltf", //四旋翼，针对Q20,
        // px4: 'cesium/model/550/whole_meshModel_withAnimation_20230830.gltf' //四旋翼，针对Q20,
      },
      yaw: 0,
      polylinePrimitive: null,
      polygonPrimitive: null,
      dTVideoFlag: false,
      currentVideoUrl: "",
      videoPointList: [], // 存储视频点列表
      videoPopups: [], // 存储多个视频弹窗的数据
      dTVideoTitle: "",
      fullScreen: false,
    };
  },
  inject: ["g_show_zysb"],
  components: {
    DTVideo,
  },
  computed: {
    ...mapGetters(["ycMapCollapse"]),
  },
  watch: {
    "$store.state.mapmanage.mapisStatus": function (newVal) {
      // 机巢模块切换视频
      if (newVal.type == "yc") {
        this.wrjFlag = false;
        this.ycFlag = newVal.flag;
        this.ycnum = newVal.num;
      } else if (newVal.type == "wrj") {
        this.ycFlag = false;
        this.ycnum = null;
        if (this.$store.state.mapmanage.videoItem == 0) {
          this.wrjFlag = newVal.flag;
          this.wrjFlag1 = false;
        }
        if (this.$store.state.mapmanage.videoItem == 1) {
          this.wrjFlag1 = newVal.flag;
          this.wrjFlag = false;
        }
      } else if (newVal.type == "fkVideo") {
        this.fkVideo = newVal.flag;
      }
    },
  },
  mounted() {
    window.cthis = this;
    this.watchPageChange();
    if (window.screen.height - window.document.body.offsetHeight > 5) {
      this.isFullscreenBool = false;
    } else {
      this.isFullscreenBool = true;
    }
    window.addEventListener("resize", this.fullScreenChangeFn);
  },
  created() {
    // 搜索关键字：飞控 机巢 左下角视频切换为全局时状态
    Bus.$on("videos_flag", (status, num) => {
      if (this.$route.path == "/fckernel1027") {
        console.log(11111111, num, "status");
        if (status == true) {
          this.yPath = true;
        } else {
          this.yPath = false;
        }
      }
    });
    Bus.$on("uav_flag", (status) => {
      if (this.$route.path == "/fckernel1027") {
        if (status == true) {
          this.uavpath = true;
        } else {
          this.uavpath = false;
        }
      }
    });
  },
  beforeDestroy() {
    try {
      window.viewer.destroy();
    } catch (e) {
      console.log("cesium-layer destroy fail", e);
    }
    window.removeEventListener("resize", this.fullScreenChangeFn);
    window.cthis = null;
  },
  methods: {
    viewer() {
      // console.log(viewer);
      // console.log(window.viewer);
      // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxx');
      return viewer;
    },
    //监听屏幕是否全屏
    fullScreenChangeFn(e) {
      if (window.screen.height - window.document.body.offsetHeight > 5) {
        this.isFullscreenBool = false;
      } else {
        this.isFullscreenBool = true;
      }
    },
    watchPageChange() {
      let services = window.___s3m;
      if (document.hidden !== undefined) {
        document.addEventListener("visibilitychange", () => {
          let status = this.$store.state.device.is3d;
          if (document.hidden) {
            if (status) {
              services.forEach((item) => {
                viewer.scene.layers.remove(item);
              });
            }
            return;
          }
          if (status) {
            // services.forEach((item) => {
            //   let url = `https://120.25.233.26:8090/iserver/services/${item}/rest/realspace/datas/Config/config`;
            //   let promise = viewer.scene.addS3MTilesLayerByScp(url, {
            //     name: item,
            //   });
            //   global_leyers.push(promise);
            // });
          }
        });
      }
    },
    async init() {
      // console.log("初始化。。。。。。。。。。。。。。");

      this.init_viewer();
      // // await this.init_plate();

      this.init_border();
      this.init_wrap_border();
      // 添加芜湖边界到地图上
      this.init_wuhu_border();
      // 深圳GeoJson数据添加到地图上
      this.init_shenzhen_polygon();
      // 添加顺德边界到地图上
      this.init_shunde_border();
      // 添加十堰市茅箭区到地图上
      this.init_shiyan_border();
      // 东台起降场
      this.addDTPoint();
      // 东台视频点
      this.addDTVideoPoint();

      this.$emit("initDone");
    },
    async init1() {
      console.log("初始化。。。。。。。。。。。。。。");

      await this.init_viewer();
      // // await this.init_plate();

      await this.init_border();
      await this.init_wrap_border();
      // 添加芜湖边界到地图上
      this.init_wuhu_border();
      // 添加顺德边界到地图上
      this.init_shunde_border();

      this.$emit("initDone");
    },
    async init_viewer() {
      Cesium.Ion.defaultAccessToken =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3YTQ0ODljYi02Njg1LTRjOGQtOGJlMC0zYjE0YmNmMDk5YTMiLCJpZCI6Nzc2NywiaWF0IjoxNjMxNzU2ODg1fQ.u8GJ2ZX5ImkQXVn9PGZ5fsvhQlfbwBIsuJ95msrPJdo";
      // 解决航线被三维模型遮盖
      var oldPolylineUpdate = Cesium.PolylineCollection.prototype.update;
      Cesium.PolylineCollection.prototype.update = function (frameState) {
        var oldMorphTime = frameState.morphTime;
        frameState.morphTime = 0.0;
        oldPolylineUpdate.call(this, frameState);
        frameState.morphTime = oldMorphTime;
      };
      window.viewer = viewer = new Cesium.Viewer("layer-container", {
        animation: false, //是否显示动画控件
        shouldAnimate: true,
        homeButton: false, //是否显示Home按钮
        fullscreenButton: false, //是否显示全屏按钮
        baseLayerPicker: false, //是否显示图层选择控件
        geocoder: true, //是否显示地名查找控件
        timeline: false, //是否显示时间线控件
        sceneModePicker: true, //是否显示投影方式控件
        navigationHelpButton: false, //是否显示帮助信息控件
        infoBox: false, //是否显示点击要素之后显示的信息
        requestRenderMode: true, //启用请求渲染模式
        maximumRenderTimeChange: Infinity,
        scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
        sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
        fullscreenElement: document.body, //全屏时渲染的HTML元素 暂时没发现用处
        selectionIndicator: false,
        imageryProvider: new Cesium.WebMapTileServiceImageryProvider({
          url: `https://{s}.tianditu.gov.cn/img_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE={style}&TILEMATRIXSET={TileMatrixSet}&FORMAT=tiles&TILEMATRIX={TileMatrix}&TILEROW={TileRow}&TILECOL={TileCol}&tk=094247775c509d9f6f2856f8fd660b33`,
          layer: "img",
          style: "default",
          format: "tiles",
          tileMatrixSetID: "c",
          credit: new Cesium.Credit("天地图全球影像服务"),
          tilingScheme: new Cesium.GeographicTilingScheme(), //采用经纬度投影
          subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
          tileMatrixLabels: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
          ],
          maximumLevel: 17,
        }),
        skyBox: false, //不显示天空盒
        orderIndependentTranslucency: true, //如果为true，并且配置支持它，则使用顺序无关的半透明性。
        contextOptions: {
          webgl: {
            alpha: true,
          },
        },
        shadows: false, // 关闭阴影
        targetFrameRate: 60, //设置最大频率数
        useBrowserRecommendedResolution: false,
      });
      // 关闭地形监测，否则影响到所有的entity
      window.viewer.scene.globe.depthTestAgainstTerrain = false; //默认为false
      // 设置最大最小缩放
      window.viewer.scene.screenSpaceCameraController.minimumZoomDistance = 0;
      window.viewer.scene.screenSpaceCameraController.maximumZoomDistance = 50000000;
      // edit 2023/5/22 修改为默认加载深色底图
      viewer.scene.backgroundColor = new Cesium.Color(0, 0, 0, 0); //将背景色设置为透明, 显示出背景图
      let services = window.___s3m;
      Object.keys(LAYERS).forEach((item) => {
        base_layer[item] = viewer.imageryLayers.addImageryProvider(
          LAYERS[item].image
          // , { show: false }
        );
      });
      base_layer.BASIC && (base_layer.BASIC.show = true);
      base_layer.BASIC && (base_layer.BASIC.show = false);
      base_layer.SATELLITE && (base_layer.SATELLITE.show = true);
      base_layer.NIGHT && (base_layer.NIGHT.show = false);
      base_layer.LAYER && (base_layer.LAYER.show = true);
      window.base_layer = base_layer;
      //添加地形数据
      Cesium.CesiumTerrainProvider.fromUrl(
        "https://www.supermapol.com/realspace/services/3D-stk_terrain/rest/realspace/datas/info/data/path",
        {
          requestWaterMask: true,
          requestVertexNormals: true,
        }
      ).then((terrainProvider) => {
        viewer.terrainProvider = terrainProvider;
      });

      // 移除原有地形
      // viewer.scene.terrainProvider = new window.Cesium.EllipsoidTerrainProvider( {} );
      // const terrainProvider = new window.Cesium.ArcGISTiledElevationTerrainProvider({
      //   url: "https://elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer",
      // });
      // viewer.scene.terrainProvider = terrainProvider;

      this.g_data.viewer = true;
      this.init_events();
      //调整光线
      let utc = Cesium.JulianDate.fromDate(new Date("2021/07/04 04:00:00"));
      // //北京时间=UTC+8=GMT+8
      viewer.clockViewModel.currentTime = Cesium.JulianDate.addHours(
        utc,
        8,
        new Cesium.JulianDate()
      );
      // this.init_tiles(); // 加载三维数据

      // viewer.scene.globe.show = false;

      // 开启监听器
      let postRender = window.viewer.scene.postRender.addEventListener((e) => {
        // 强制再请求渲染，有时候模型的旋翼卡住了，这个办法可以避免模型旋翼卡住
        window.viewer.scene.requestRender();
      });
      // 添加网格
      // let grids = await API.AIRSPACE.getAirSpaceGeoSotGrid({ level: 16 })
      // console.log("===================", grids)
      // if (grids.length > 0) {
      //   this.addGrids(grids);
      // } else {
      //   this.addGrids(wuhuGeoSot17);
      // }
      // 网格码加载
      // API.AIRSPACE.getAirSpaceGeoSotGrid({ level: 16 }).then((grids) => {
      //   // console.log(grids, '初始化的网格码');
      //   // console.log("===================", grids)
      //   if (grids.length > 0) {
      //     this.addGrids(grids)
      //   } else {
      //     this.addGrids(wuhuGeoSot17)
      //   }
      // })
      // 添加芜湖消防支队影像
      this.addWHXFZDImageLayer();
      // 添加湾沚南湖印三维模型
      this.loadWZNHY3DTiles();
      // 添加几个临时点
      this.addPointTemporary();
      // 添加几个高分辨率影像数据
      this.addShenzhenLonggangLayer();
      this.addShiyanMaojianLayer();
      this.addWuhuWanzhiLayer();
      this.addZhengzhouJinshuiLayer();
      this.addZhengzhouZhongyuanLayer();
      this.addFoShanShunDeLayer();
      this.addShenZhenJDDeLayer();

      // t6.tianditu.gov.cn
      let tiandituImageLabelLayer1 = new Cesium.ImageryLayer(
        new Cesium.WebMapTileServiceImageryProvider({
          url: "https://{s}.tianditu.gov.cn/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=094247775c509d9f6f2856f8fd660b33",
          // url: "http://localhost:6004/m2/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=094247775c509d9f6f2856f8fd660b33",
          layer: "tdtAnnoLayer",
          style: "default",
          format: "image/jpeg",
          tileMatrixSetID: "GoogleMapsCompatible",
          subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
          crossorigin: "anonymous",
        })
      );
      window.viewer.imageryLayers.add(tiandituImageLabelLayer1);
      this.addAirSpaceLine();
    },
    async init_viewer1() {
      Cesium.Ion.defaultAccessToken =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3YTQ0ODljYi02Njg1LTRjOGQtOGJlMC0zYjE0YmNmMDk5YTMiLCJpZCI6Nzc2NywiaWF0IjoxNjMxNzU2ODg1fQ.u8GJ2ZX5ImkQXVn9PGZ5fsvhQlfbwBIsuJ95msrPJdo";

      // return;
      // 解决航线被三维模型遮盖
      var oldPolylineUpdate = Cesium.PolylineCollection.prototype.update;
      Cesium.PolylineCollection.prototype.update = function (frameState) {
        var oldMorphTime = frameState.morphTime;
        frameState.morphTime = 0.0;
        oldPolylineUpdate.call(this, frameState);
        frameState.morphTime = oldMorphTime;
      };

      window.viewer = viewer = new Cesium.Viewer("layer-container", {
        animation: false, //是否显示动画控件
        shouldAnimate: true,
        homeButton: false, //是否显示Home按钮
        fullscreenButton: false, //是否显示全屏按钮
        baseLayerPicker: false, //是否显示图层选择控件
        geocoder: true, //是否显示地名查找控件
        timeline: false, //是否显示时间线控件
        sceneModePicker: true, //是否显示投影方式控件
        navigationHelpButton: false, //是否显示帮助信息控件
        infoBox: false, //是否显示点击要素之后显示的信息
        requestRenderMode: true, //启用请求渲染模式
        maximumRenderTimeChange: Infinity,
        scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
        sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
        fullscreenElement: document.body, //全屏时渲染的HTML元素 暂时没发现用处
        selectionIndicator: false,
        // imageryProvider: false,
        // imageryProvider: new Cesium.ArcGisMapServerImageryProvider({
        //   url: "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer",
        // }),
        /*  imageryProvider: new Cesium.UrlTemplateImageryProvider({
          url: "",
        }), */
        // imageryProvider: new Cesium.TileMapServiceImageryProvider({
        //   url: Cesium.buildModuleUrl("Assets/Textures/NaturalEarthII"),
        // }),
        skyBox: false, //不显示天空盒
        orderIndependentTranslucency: true, //如果为true，并且配置支持它，则使用顺序无关的半透明性。
        contextOptions: {
          //传递给 Scene 的与 options 相对应的上下文和WebGL创建属性
          webgl: {
            alpha: true,
          },
        },
        shadows: false, // 关闭阴影
        // skyAtmosphere: false, // 关闭大气层渲染

        targetFrameRate: 60, //设置最大频率数
        // resolutionScale: .5, //清晰度 0-1
        useBrowserRecommendedResolution: false,
      });
      // window.viewer.scene.globe.depthTestAgainstTerrain = true; //默认为false

      // return;

      // 设置最大最小缩放
      window.viewer.scene.screenSpaceCameraController.minimumZoomDistance = 500;
      window.viewer.scene.screenSpaceCameraController.maximumZoomDistance = 50000000;

      // 添加天地图注记
      let tiandituImageLabelLayer = new Cesium.ImageryLayer(
        new Cesium.WebMapTileServiceImageryProvider({
          url: "https://{s}.tianditu.com/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=094247775c509d9f6f2856f8fd660b33",
          // url: "http://localhost:6004/m2/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=094247775c509d9f6f2856f8fd660b33",
          layer: "tdtAnnoLayer",
          style: "default",
          format: "image/jpeg",
          tileMatrixSetID: "GoogleMapsCompatible",
          subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
          crossorigin: "anonymous",
        }),
        {
          show: true,
          alpha: 1,
        }
      );

      if (this.$route.path == "/home") {
        viewer.scene.globe.show = false;
        if (tiandituImageLabelLayer) {
          tiandituImageLabelLayer.show = false;
        }
      } else {
        if (tiandituImageLabelLayer) {
          tiandituImageLabelLayer.show = true;
        }
      }
      base_layer.TDTYXZJ = tiandituImageLabelLayer;

      // edit 2023/5/22 修改为默认加载深色底图
      viewer.scene.backgroundColor = new Cesium.Color(0, 0, 0, 0); //将背景色设置为透明, 显示出背景图
      // let services = window.___s3m;

      Object.keys(LAYERS).forEach((item) => {
        base_layer[item] = viewer.imageryLayers.addImageryProvider(
          LAYERS[item].image
          // , { show: false }
        );
      });
      // base_layer.BASIC && (base_layer.BASIC.show = true);
      base_layer.BASIC && (base_layer.BASIC.show = false);
      base_layer.SATELLITE && (base_layer.SATELLITE.show = true);
      // base_layer.NIGHT && (base_layer.NIGHT.show = false)
      // base_layer.LAYER && (base_layer.LAYER.show = true);
      window.base_layer = base_layer;

      window.viewer.imageryLayers.add(tiandituImageLabelLayer);
      window.viewer.imageryLayers.raiseToTop(tiandituImageLabelLayer);

      // if (Cesium.FeatureDetection.supportsImageRenderingPixelated()) {
      //   let vtxf_dpr = window.devicePixelRatio;
      //   // 适度降低分辨率
      //   while (vtxf_dpr >= 2.0) {
      //     vtxf_dpr /= 2.0;
      //   }
      //   //alert(dpr);
      //   window.viewer.resolutionScale = vtxf_dpr;
      // }

      // window.viewer.scene.sun.show = false;
      // window.viewer.scene.moon.show = false;

      //添加地形数据
      // console.log(Cesium, 'REVERSION');
      // let terrainProvider = new Cesium.CesiumTerrainProvider({
      //   url: 'https://www.supermapol.com/realspace/services/3D-stk_terrain/rest/realspace/datas/info/data/path'
      //   // url: 'http://localhost:6003/m1/'
      // });

      // let terrainProvider = await Cesium.CesiumTerrainProvider.fromUrl('https://www.supermapol.com/realspace/services/3D-stk_terrain/rest/realspace/datas/info/data/path');
      Cesium.CesiumTerrainProvider.fromUrl(
        "https://www.supermapol.com/realspace/services/3D-stk_terrain/rest/realspace/datas/info/data/path"
      ).then((terrainProvider) => {
        viewer.terrainProvider = terrainProvider;
      });

      // viewer.scene.globe.terrainExaggeration = 2.0;

      // terrainProvider = new Cesium.ArcGISTiledElevationTerrainProvider({
      //   url: 'https://elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer',
      //   token: 'AAPK08c297f4f997466a80de945e84463567NL1Ybz69t8-n_YzXRfBG1tv_hXvS5yNJSr2KD3i9ggn5piFzlc6et5pMxWwB4y31'
      // });
      // window.viewer.terrainProvider = terrainProvider;

      // let terrainProvider = Cesium.createWorldTerrain();
      // window.viewer.terrainProvider = terrainProvider;

      // 注释掉模型数据的添加
      // services.forEach((item) => {
      //   let url = `http://120.25.233.26:8090/iserver/services/${item}/rest/realspace/datas/Config/config`;
      //   // console.log('s3m url', url)
      //   let promise = viewer.scene.addS3MTilesLayerByScp(url, {
      //     name: item,
      //     // cullEnabled: false
      //   });
      //   /* promise.then((layer) => {
      //       layer.visible = false;
      //     }); */
      //   global_leyers.push(promise);
      // });

      // return
      this.g_data.viewer = true;
      this.init_events();
      //调整光线
      let utc = Cesium.JulianDate.fromDate(new Date("2021/07/04 04:00:00"));
      // //北京时间=UTC+8=GMT+8
      viewer.clockViewModel.currentTime = Cesium.JulianDate.addHours(
        utc,
        8,
        new Cesium.JulianDate()
      );
      // this.init_tiles(); // 加载三维数据

      // viewer.scene.globe.show = false;

      // 开启监听器
      let postRender = window.viewer.scene.postRender.addEventListener((e) => {
        // 强制再请求渲染，有时候模型的旋翼卡住了，这个办法可以避免模型旋翼卡住
        window.viewer.scene.requestRender();
      });
      // 添加网格
      // let grids = await API.AIRSPACE.getAirSpaceGeoSotGrid({ level: 16 })
      // console.log("===================", grids)
      // if (grids.length > 0) {
      //   this.addGrids(grids);
      // } else {
      //   this.addGrids(wuhuGeoSot17);
      // }
      // 网格码加载太慢，先不加了
      // API.AIRSPACE.getAirSpaceGeoSotGrid({ level: 16 }).then((grids) => {
      //   console.log(grids, '初始化的网格码');
      //   // console.log("===================", grids)
      //   if (grids.length > 0) {
      //     this.addGrids(grids);
      //   } else {
      //     this.addGrids(wuhuGeoSot17);
      //   }
      // });
    },
    init_events() {
      let handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction((movement) => {
        // 点击事件获得实体
        this.on_plate_left_click(movement);
        // 拾取entity
        let pick = viewer.scene.pick(movement.position); // 获取 pick 拾取对象
        if (Cesium.defined(pick)) {
          let en = pick.id;
          if (en._name.indexOf("dt-video-point") != -1) {
            console.log("pick.id", pick.id);
            this.dTVideoFlag = true;
            this.dTVideoTitle = en._name.replace("dt-video-point-", "");
            this.fullScreen = false;
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      handler.setInputAction((movement) => {
        //获取当前相机高度
        // if (this.is_plate_mode()) {
        let height = Math.ceil(viewer.camera.positionCartographic.height);
        this.on_plate_wheel(height);
        // }
      }, Cesium.ScreenSpaceEventType.WHEEL);
    },
    async init_tiles() {
      let res = await API.MAPMANAGE.GetLayerList();
      console.log(res, "resresresresresresresresres模型");
      let regexp = /^.*(\.json)$/;
      res[1].children.forEach((item) => {
        if (regexp.test(item.url)) {
          let primitive = viewer.scene.primitives.add(
            new Cesium.Cesium3DTileset({
              url: item.url,
              // maximumScreenSpaceError: 2, // The maximum screen space error used to drive level of detail refinement. 值越小细节越好，默认16
              maximumScreenSpaceError: 64, // The maximum screen space error used to drive level of detail refinement. 值越小细节越好，默认16
              maximumMemoryUsage: 10240, // The maximum amount of memory in MB that can be used by the tileset. 显存调整到1G，目前低配的计算机显存差不多这个参数
              // skipLevelOfDetail: true
              dynamicScreenSpaceError: true,
              show: false,
            })
          );
          primitives_3d_tiles[item.id] = primitive;
        }
      });

      // 调整模型位置
      // tileset.then((model) => {
      //   let cartographic = Cesium.Cartographic.fromCartesian(
      //       model.boundingSphere.center
      //     ),
      //     surface = Cesium.Cartesian3.fromRadians(
      //       cartographic.longitude,
      //       cartographic.latitude,
      //       0
      //     ),
      //     offset = Cesium.Cartesian3.fromRadians(
      //       cartographic.longitude,
      //       cartographic.latitude,
      //       0
      //     ),
      //     translation = Cesium.Cartesian3.subtract(
      //       offset,
      //       surface,
      //       new Cesium.Cartesian3()
      //     );
      //   model.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
      // });
    },
    async init_border() {
      let promise = Cesium.GeoJsonDataSource.load(GeoJsonBorder);
      // let dataSource = promise;
      promise.then((dataSource) => {
        let entities = dataSource.entities.values;
        for (let i = 0; i < entities.length; i++) {
          let entity = entities[i];

          entity._name = "border" + "_" + entity._name;
          entity.polygon.outline = true;
          entity.polygon.fill = false;
          entity.polygon.outlineColor =
            Cesium.Color.fromCssColorString("#ffd33c");

          let positions = entity.polygon.hierarchy._value.positions;

          entity.polyline = {
            positions: positions.map((item) => {
              let c = Cesium.Cartesian3.clone(item);
              let wgs84 = Utils.transformCartesian2WGS84(c);
              return Utils.transformWGS842Cartesian(wgs84);
            }),
            width: 4,
            material: Cesium.Color.fromCssColorString("#36b558"),
            // material: Cesium.Color.fromCssColorString('#ffb319'),
          };
        }
        viewer.dataSources.add(dataSource);
        dataSource.show = true;
        border_data_source = dataSource;
      });
    },
    async init_border1() {
      let promise = Cesium.GeoJsonDataSource.load(GeoJsonBorder);
      let dataSource = await promise;
      let entities = dataSource.entities.values;
      for (let i = 0; i < entities.length; i++) {
        let entity = entities[i];

        entity._name = "border" + "_" + entity._name;
        entity.polygon.outline = true;
        entity.polygon.fill = false;
        entity.polygon.outlineColor =
          Cesium.Color.fromCssColorString("#ffd33c");

        let positions = entity.polygon.hierarchy._value.positions;

        entity.polyline = {
          positions: positions.map((item) => {
            let c = Cesium.Cartesian3.clone(item);
            let wgs84 = Utils.transformCartesian2WGS84(c);
            return Utils.transformWGS842Cartesian(wgs84);
          }),
          width: 4,
          material: Cesium.Color.fromCssColorString("#36b558"),
          // material: Cesium.Color.fromCssColorString('#ffb319'),
        };
      }
      await viewer.dataSources.add(dataSource);
      dataSource.show = true;
      border_data_source = dataSource;
    },
    async init_wrap_border() {
      let promise = Cesium.GeoJsonDataSource.load(GeoJson);
      // let dataSource = promise;
      promise.then((dataSource) => {
        let entities = dataSource.entities.values;
        for (let i = 0; i < entities.length; i++) {
          let entity = entities[i];
          entity._name = "border" + "_" + entity._name;
          entity.polygon.outline = true;
          entity.polygon.fill = false;
          entity.polygon.outlineColor = Cesium.Color.fromCssColorString("red");
          let positions = entity.polygon.hierarchy._value.positions;
          entity.polyline = {
            positions: positions.map((item) => {
              let c = Cesium.Cartesian3.clone(item);
              let wgs84 = Utils.transformCartesian2WGS84(c);
              return Utils.transformWGS842Cartesian(wgs84);
            }),
            width: 4,
            material: Cesium.Color.fromCssColorString("#1645fc"), //地图底部边线颜色
          };
        }
        viewer.dataSources.add(dataSource);
        dataSource.show = true;
        border_data_source = dataSource;
      });
    },
    async init_wrap_border1() {
      let promise = Cesium.GeoJsonDataSource.load(GeoJson);
      let dataSource = await promise;
      let entities = dataSource.entities.values;
      for (let i = 0; i < entities.length; i++) {
        let entity = entities[i];

        entity._name = "border" + "_" + entity._name;
        entity.polygon.outline = true;
        entity.polygon.fill = false;
        entity.polygon.outlineColor = Cesium.Color.fromCssColorString("red");

        let positions = entity.polygon.hierarchy._value.positions;

        entity.polyline = {
          positions: positions.map((item) => {
            let c = Cesium.Cartesian3.clone(item);
            let wgs84 = Utils.transformCartesian2WGS84(c);
            return Utils.transformWGS842Cartesian(wgs84);
          }),
          width: 4,
          material: Cesium.Color.fromCssColorString("#1645fc"), //地图底部边线颜色
        };
      }
      await viewer.dataSources.add(dataSource);
      dataSource.show = true;
      border_data_source = dataSource;
    },
    async init_plate() {
      let promise = Cesium.GeoJsonDataSource.load(GeoJson);
      let dataSource = await promise;
      let entities = dataSource.entities.values;
      let center_hash = {};
      // console.log('entities', entities)
      for (let i = 0; i < entities.length; i++) {
        let entity = entities[i],
          name = entity.name,
          centerHash = {};
        let areaName = center_hash[name] ? `${name}1` : name; //出现地区重名则在名字后+'1'

        entity._name = "plate" + "_" + entity._name;
        entity.polygon.outline = true;
        entity.polygon.outlineColor =
          Cesium.Color.fromCssColorString("#218CDB"); // 边线填充色
        entity.polygon.material = Cesium.Color.fromCssColorString(
          "#2A9AFF" //填充色
        ).withAlpha(1);
        entity.polygon.extrudedHeight = Math.floor(10000);
        entity.polygon.material = `/static/img/cesium/${areaName}.png`;

        let positions = entity.polygon.hierarchy._value.positions;

        entity.polyline = {
          positions: positions.map((item) => {
            let c = Cesium.Cartesian3.clone(item);
            let wgs84 = Utils.transformCartesian2WGS84(c);
            wgs84.alt += 10020;
            return Utils.transformWGS842Cartesian(wgs84);
          }),
          width: 4,
          //边框
          material: Cesium.Color.fromCssColorString("#B3D9FF"), // 边框线的颜色
        };

        let center = entity.properties.centroid._value;
        center = Utils.transformWGS842Cartesian({
          lng: center[0],
          lat: center[1],
          alt: 10000,
        });

        if (!center_hash[name]) {
          entity.position = center;
          entity.label = {
            text: name,
            show: true,
            showBackground: false,
            font: "16px monospace bolder",
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            pixelOffset: new Cesium.Cartesian2(0, 20),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            //字体
            fillColor: Cesium.Color.fromCssColorString("#fff"), // 字体颜色
            scaleByDistance: new Cesium.NearFarScalar(90000, 3, 660000, 0.4),
            // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(90000, 5, 460000, 1)
          };
          entity.billboard = {
            show: true,
            image: `/static/img/cesium/poi_${name}.png`,
            pixelOffset: new Cesium.Cartesian2(0, -40),
            width: 43,
            height: 75,
            scale: 1,
            scaleByDistance: new Cesium.NearFarScalar(90000, 5, 660000, 0.4),
            pixelOffsetScaleByDistance: new Cesium.NearFarScalar(
              90000,
              5,
              660000,
              0.4
            ),
          };
          center_hash[name] = center;
        }
      }

      await viewer.dataSources.add(dataSource);
      dataSource.show = false;
      window.plate_data_source = dataSource;
    },

    /** 添加芜湖边界
     *@Description: 添加芜湖边界
     *@Date: 2023-10-08 17:14:47
     *@Params1:
     *@Return1:
     */
    init_wuhu_border() {
      // console.log(WuHuGeoJsonBorder, 'WuHuGeoJsonBorder');
      let promise = Cesium.GeoJsonDataSource.load(WuHuGeoJsonBorder);
      // let dataSource = promise;
      promise.then((dataSource) => {
        let entities = dataSource.entities.values;
        for (let i = 0; i < entities.length; i++) {
          let entity = entities[i];
          entity._name = "border" + "_" + entity._name + "_wuhu";
          entity.polygon.outline = true;
          entity.polygon.fill = false;
          entity.polygon.outlineColor =
            Cesium.Color.fromCssColorString("#ffd33c");
          let positions = entity.polygon.hierarchy._value.positions;
          entity.polyline = {
            positions: positions.map((item) => {
              let c = Cesium.Cartesian3.clone(item);
              let wgs84 = Utils.transformCartesian2WGS84(c);
              return Utils.transformWGS842Cartesian(wgs84);
              // return c;
            }),
            width: 4,
            material: Cesium.Color.fromCssColorString("#36b558"),
            clampToGround: true,
            // material: Cesium.Color.fromCssColorString('#ffb319'),
          };
        }
        viewer.dataSources.add(dataSource);
        dataSource.show = true;
        border_data_source_wuhu = dataSource;
      });
    },
    /**@Description :  ******************************************* 添加顺德边界
     **@Date: 2024-05-18 16:19:08
     */
    init_shunde_border() {
      // console.log(WuHuGeoJsonBorder, 'WuHuGeoJsonBorderWuHuGeoJsonBorder');
      let promise = Cesium.GeoJsonDataSource.load(ShunDeGeoJsonBorder);
      // let dataSource = promise;
      promise.then((dataSource) => {
        let entities = dataSource.entities.values;
        // console.log('', entities);
        for (let i = 0; i < entities.length; i++) {
          let entity = entities[i];
          entity._name = "border" + "_" + entity._name + "_shunde";
          entity.polygon.outline = true;
          entity.polygon.fill = true;
          entity.polygon.material = Cesium.Color.fromCssColorString(
            "rgba(54, 181, 88, 0.0)"
          );
          entity.polygon.outlineColor =
            Cesium.Color.fromCssColorString("#ffd33c");
          let positions = entity.polygon.hierarchy._value.positions;
          entity.polyline = {
            positions: positions.map((item) => {
              let c = Cesium.Cartesian3.clone(item);
              let wgs84 = Utils.transformCartesian2WGS84(c);
              return Utils.transformWGS842Cartesian(wgs84);
            }),
            width: 4,
            material: Cesium.Color.fromCssColorString("#36b558"),
            clampToGround: true,
            // material: Cesium.Color.fromCssColorString('#ffb319'),
          };
        }
        viewer.dataSources.add(dataSource);
        dataSource.show = true;
        border_data_source_shunde = dataSource;
      });
    },
    /**@Description :  ******************************************* 添加十堰边界
     **@Date: 2024-06-06 10:20:10
     */
    init_shiyan_border() {
      // console.log('MaoJIanGeoJsonBorder', ShiYanGeoJsonBorder);
      let promise = Cesium.GeoJsonDataSource.load(ShiYanGeoJsonBorder);
      promise.then((dataSource) => {
        // console.log('dataSource', dataSource);
        let entities = dataSource.entities.values;
        for (let i = 0; i < entities.length; i++) {
          // console.log('entities_mj', entities);
          let entity = entities[i];
          entity._name = "border" + "_" + entity._name + "_shiyan";
          entity.polygon.outline = true;
          entity.polygon.fill = true;
          entity.polygon.material = Cesium.Color.fromCssColorString(
            "rgba(54, 181, 88, 0.0)"
          );
          entity.polygon.outlineColor =
            Cesium.Color.fromCssColorString("#ffd33c");
          let positions = entity.polygon.hierarchy._value.positions;
          entity.polyline = {
            positions: positions.map((item) => {
              let c = Cesium.Cartesian3.clone(item);
              let wgs84 = Utils.transformCartesian2WGS84(c);
              return Utils.transformWGS842Cartesian(wgs84);
            }),
            width: 4,
            material: Cesium.Color.fromCssColorString("#36b558"),
            clampToGround: true,
          };
        }
        viewer.dataSources.add(dataSource);
        dataSource.show = true;
        border_data_source_shiyan = dataSource;
      });
    },
    get_plate_by_click(moment) {
      let pickedObject = viewer.scene.pick(moment.position);
      if (pickedObject?.id?._name) {
        if (
          pickedObject &&
          pickedObject.id &&
          pickedObject.id._name.indexOf("plate_") != -1
        ) {
          return pickedObject.id;
        } else {
          return null;
        }
      }
    },

    on_plate_left_click(movement) {
      let plate = this.get_plate_by_click(movement);
      if (plate) {
        let center = plate.properties.center._value;
        this.fly_to_globe({
          destination: Utils.transformWGS842Cartesian({
            lng: center[0],
            lat: center[1],
            alt: 10000,
          }),
        });
      }
    },
    on_plate_wheel(height) {
      if (height >= 1.4e7 && !window.plate_data_source.show) {
        this.fly_to_plate();
      }
    },
    is_plate_mode() {
      return window.plate_data_source && window.plate_data_source.show;
    },
    location(options = { destination: origin }, fly_type = "flyTo") {
      // return
      // console.log(options, 'optionsoptionsoptionsoptionsoptions')
      // console.log(fly_type, 'fly_typefly_typefly_typefly_type')
      // viewer.camera[fly_type](options);
      Utils.initLocation2WUHU();
    },
    fly_to_globe(options) {
      console.log(options, "fly_to_globe");
      viewer.trackedEntity && (viewer.trackedEntity = undefined);

      viewer.scene.globe.show = true;

      viewer.scene.skyAtmosphere.show = true;
      viewer.scene.globe.showGroundAtmosphere = true;

      window.plate_data_source && (window.plate_data_source.show = false);
      // console.log(options, 'options')
      // return
      this.location(options);
    },
    // fly_to_plate() {
    //   viewer.scene.skyAtmosphere.show = false;
    //   viewer.scene.globe.showGroundAtmosphere = false;
    //   viewer.scene.globe.show = false;
    //   window.plate_data_source && (window.plate_data_source.show = true);
    //   this.location(
    //     {
    //       destination: plate_orign,
    //       orientation: plate_hpr,
    //     },
    //     "setView"
    //   );
    //   center && (viewer.trackedEntity = center);
    // },

    fly_to_plate(fly_type = "setView", options) {
      console.log(options, "fly_to_plate");
      window.plate_data_source && (window.plate_data_source.show = true);

      options = {};
      options.destination = plate_orign;
      options.orientation = plate_hpr;
      options.duration = 5;
      options.complete = () => {
        viewer.scene && (viewer.scene.skyAtmosphere.show = false);
        viewer.scene && (viewer.scene.globe.showGroundAtmosphere = false);
        viewer.scene && (viewer.scene.globe.show = false);
      };
      fly_type === "setView" && options.complete && options.complete();

      this.location(options, fly_type);
      center && (viewer.trackedEntity = center);
    },

    // fly_to_by_mode(mode = MODES.PLATE) {
    //   if (mode === MODES.PLATE) {
    //     this.fly_to_plate();
    //   } else if (mode === MODES.GLOBE) {
    //     this.fly_to_globe();
    //   }
    // },

    async fly_to_by_mode(mode = MODES.PLATE, ...options) {
      console.log(options, "fly_to_by_mode");

      // return;
      // debugger
      // console.log(mode, 'modemodemodemodemode');
      if (mode === MODES.PLATE) {
        this.fly_to_plate(...options);
      } else if (mode === MODES.GLOBE) {
        // debugger
        this.fly_to_globe();
      }

      // // 定位到芜湖
      // await Utils.initLocation2WUHU();
    },
    add_model(entities, data) {
      // debugger
      // console.log('XXXXXXXXXXXXXXXXXX');
      let flag = 2;
      // let uri = arguments[2] ? this.uavType[String(arguments[2]['cateName'])] : this.uavType['px4'];
      let uri = "";
      // let cateName = arguments[2] ? String(arguments[2]['cateName']) : 'px4';
      // console.log(arguments[2], 'arguments[2]arguments[2]arguments[2]arguments[2]');
      let cateName = arguments[2] ? String(arguments[2]["cateName"]) : "apm";
      console.log(cateName, "cateName");
      // cateName = 'px4_temp'
      // cateName = 'apm';
      // apm和px4都设置成 TD-Q20
      if (cateName == "apm") {
        cateName = "px4";
      }
      if (flag == 1) {
        uri = this.uavType[`${cateName}`];
        return entities.add({
          id: data.entity_id,
          parent: data.parent || null,
          orientation: new Cesium.CallbackProperty(
            () => data.orientation,
            false
          ),
          position: new Cesium.CallbackProperty(() => data.position, false),
          model: {
            uri: uri,
            // uri: "cesium/model/1800_20210316_no_effect283g2color.gltf",
            // uri: "cesium/model/mmcModel2.gltf",
            // uri: "cesium/model/drone_costum.glb",
            minimumPixelSize: 100,
            maximumScale: 10000,
            scale: 1,
            // scale: 10,
            ...(data.options ? data.options : {}),
            // show: new Cesium.CallbackProperty(
            //   () => !window.plate_data_source || !window.plate_data_source.show,
            //   false
            // ),
          },
        });
      }

      if (flag == 2) {
        // debugger
        console.log(cateName, "cateName");
        // cateName = 'px4';
        if (cateName == "amp") {
          // 加载TD220
          return this.add_model_TD550(entities, data, this.uavType["amp"]);
        } else if (cateName == "px4") {
          // return this.add_model_Q20(entities, data, this.uavType['px4']);
          // return this.add_model_550(entities, data, this.uavType['px4']);
          return this.add_model_Q20_single(entities, data, this.uavType["px4"]);
        } else if (cateName == "apm") {
          return this.add_model_six(entities, data, this.uavType["apm"]);
          // uri = this.uavType[`${cateName}`];
          // return entities.add({
          //   id: data.entity_id,
          //   parent: data.parent || null,
          //   orientation: new Cesium.CallbackProperty(() => data.orientation, false),
          //   position: new Cesium.CallbackProperty(() => data.position, false),
          //   model: {
          //     uri: uri,
          //     minimumPixelSize: 100,
          //     maximumScale: 10000,
          //     // scale: 1.5,
          //     scale: 60,
          //     ...(data.options ? data.options : {}),
          //     // show: new Cesium.CallbackProperty(
          //     //   () => !window.plate_data_source || !window.plate_data_source.show,
          //     //   false
          //     // ),
          //   },
          // });
        } else {
          uri = this.uavType[`${cateName}`];
          return entities.add({
            id: data.entity_id,
            parent: data.parent || null,
            orientation: new Cesium.CallbackProperty(
              () => data.orientation,
              false
            ),
            position: new Cesium.CallbackProperty(() => data.position, false),
            model: {
              uri: uri,
              // uri: "cesium/model/1800_20210316_no_effect283g2color.gltf",
              // uri: "cesium/model/mmcModel2.gltf",
              // uri: "cesium/model/drone_costum.glb",
              minimumPixelSize: 100,
              maximumScale: 10000,
              scale: 1.5,
              // scale: 10,
              ...(data.options ? data.options : {}),
              // show: new Cesium.CallbackProperty(
              //   () => !window.plate_data_source || !window.plate_data_source.show,
              //   false
              // ),
            },
          });
        }
      }
    },
    // 添加TD550模型 返回机体、上叶浆、下叶浆模型三个 数组形式
    add_model_TD550(entities, data, uriArray) {
      let bodyEntity = new Cesium.Entity({
        id: data.entity_id,
        name: data.entity_id + "_body",
        parent: data.parent || null,
        // orientation: new Cesium.CallbackProperty(() => {
        //   return data.orientation;
        // }, false),
        // position: new Cesium.CallbackProperty(() => data.position, false),
        position: data.position,
        model: {
          uri: uriArray[0],
          minimumPixelSize: 100,
          maximumScale: 1000000,
          scale: 30,
          // scale: 55300,
          ...(data.options ? data.options : {}),
        },
      });
      let lower_rotar_Entity = new Cesium.Entity({
        id: data.entity_id + "_lower_rotar",
        parent: data.parent || null,
        orientation: new Cesium.CallbackProperty(() => {
          return data.orientation;
        }, false),
        // position: new Cesium.CallbackProperty(() => data.position, false),
        position: data.position,
        model: {
          uri: uriArray[1],
          minimumPixelSize: 100,
          maximumScale: 100000,
          scale: 28,
          // scale: 55280,
          ...(data.options ? data.options : {}),
        },
      });
      let upper_rotar_Entity = new Cesium.Entity({
        id: data.entity_id + "_upper_rotar",
        parent: data.parent || null,
        orientation: new Cesium.CallbackProperty(() => {
          return data.orientation;
        }, false),
        // position: new Cesium.CallbackProperty(() => data.position, false),
        position: data.position,
        model: {
          uri: uriArray[2],
          minimumPixelSize: 100,
          maximumScale: 100000,
          scale: 28,
          // scale: 55280,
          ...(data.options ? data.options : {}),
        },
      });
      let body = entities.add(bodyEntity);
      entities.add(lower_rotar_Entity);
      entities.add(upper_rotar_Entity);
      let headerFlag = 0;
      // let prePosition = lower_rotar_Entity.position.getValue();
      // function loop () {
      //   console.log(body.show);
      //   if (body.show) {
      //     let position1 = lower_rotar_Entity.position.getValue();//求出当前事件entity的位置
      //     let position2 = upper_rotar_Entity.position.getValue();//求出当前事件entity的位置
      //     let h = Cesium.Math.toRadians(headerFlag);
      //     let orientation1 = Cesium.Transforms.headingPitchRollQuaternion(position1, new Cesium.HeadingPitchRoll(h, 0, 0));
      //     let orientation2 = Cesium.Transforms.headingPitchRollQuaternion(position2, new Cesium.HeadingPitchRoll(h, 0, 0));
      //     lower_rotar_Entity.orientation = orientation1;
      //     upper_rotar_Entity.orientation = orientation2;
      //     headerFlag += 2;
      //     if (headerFlag >= 360) headerFlag = 0;
      //   }
      //   requestAnimationFrame(loop);
      // }
      // loop();
      let a = new Cesium.CallbackProperty((result, time) => {
        headerFlag += 1.5;
        if (headerFlag >= 360) headerFlag = 0;
        let position = null;
        let position1 = bodyEntity.position.getValue(
          Cesium.JulianDate.fromDate(new Date())
        ); //求出当前时间entity的位置
        // console.log(position1, 'position1');
        // debugger
        // console.log(bodyEntity, 'bodyEntity');
        // console.log(time, 'RRRRRRRRRRRRRRRRRRR');
        let h = Cesium.Math.toRadians(headerFlag);
        let p = Cesium.Math.toRadians(90);
        let r = Cesium.Math.toRadians(90);
        position = new Cesium.Cartesian3.fromDegrees(10, 0, 0);
        let orientation = Cesium.Transforms.headingPitchRollQuaternion(
          position1,
          new Cesium.HeadingPitchRoll(h, 0, 0)
        );
        return orientation;
      }, false);
      // 当前位置
      // let prePosition = bodyEntity.position.getValue();//求出当前时间entity的位置
      // let b = new Cesium.CallbackProperty((result, time) => {
      //   let position = bodyEntity.position.getValue();//求出当前时间entity的位置
      //   // 求解上一次位置和本次位置连线的方位角
      //   // if (!prePosition.equals(position)) {
      //     let ang = this.bearing(prePosition, position);
      //     console.log(ang, 'ang');
      //     let h = Cesium.Math.toRadians(327);
      //     let orientation = Cesium.Transforms.headingPitchRollQuaternion(position, new Cesium.HeadingPitchRoll(h, 0, 0));
      //     prePosition = position;
      //     return orientation;
      //   // }
      // }, false);
      // lower_rotar_Entity.orientation = a
      // upper_rotar_Entity.orientation = a
      // bodyEntity.orientation = b;
      // window.upper_rotar_Entity = upper_rotar_Entity
      // window.lower_rotar_Entity = lower_rotar_Entity
      return [body, upper_rotar_Entity, lower_rotar_Entity];
    },
    // 添加TD220模型上叶浆
    // 添加Q20模型
    add_model_Q20(entities, data, uriArray) {
      let bodyEntity = new Cesium.Entity({
        id: data.entity_id,
        name: data.entity_id + "_body",
        parent: data.parent || null,
        position: new Cesium.CallbackProperty(() => data.position, false),
        model: {
          uri: uriArray[0],
          minimumPixelSize: 100,
          maximumScale: 10000,
          scale: 80,
          ...(data.options ? data.options : {}),
        },
      });
      // let lower_rotar_Entity = new Cesium.Entity({
      //   id: data.entity_id + '_lower_rotar',
      //   parent: data.parent || null,
      //   orientation: new Cesium.CallbackProperty(() => {
      //     return data.orientation;
      //   }, false),
      //   position: new Cesium.CallbackProperty(() => {
      //     let pos = data.position;
      //     // console.log(pos, 'pos');
      //     // c3 转经纬度
      //     let cartographic = window.viewer.scene.globe.ellipsoid.cartesianToCartographic(pos);
      //     let lon = Cesium.Math.toDegrees(cartographic.longitude);
      //     let lat = Cesium.Math.toDegrees(cartographic.latitude);
      //     let height = parseFloat(cartographic.height) + 0;
      //     let pos1 = new Cesium.Cartesian3.fromDegrees(lon, lat, height);
      //     return pos1;
      //   }, false),
      //   model: {
      //     uri: uriArray[1] && uriArray[1],
      //     minimumPixelSize: 100,
      //     maximumScale: 10000,
      //     scale: 1,
      //     ...(data.options ? data.options : {}),
      //   },
      // });

      let body = entities.add(bodyEntity);
      // entities.add(lower_rotar_Entity);
      let headerFlag = 0;
      // let a = new Cesium.CallbackProperty((result, time) => {
      //   headerFlag += 4;
      //   if (headerFlag >= 360) headerFlag = 0;
      //   let position = bodyEntity.position.getValue();//求出当前时间entity的位置
      //   let h = Cesium.Math.toRadians(headerFlag);
      //   let orientation = Cesium.Transforms.headingPitchRollQuaternion(position, new Cesium.HeadingPitchRoll(h, 0, 0));
      //   return orientation;
      // }, false);
      // 当前位置
      let prePosition = bodyEntity.position.getValue(); //求出当前时间entity的位置
      let b = new Cesium.CallbackProperty((result, time) => {
        let position = bodyEntity.position.getValue(); //求出当前时间entity的位置
        // 求解上一次位置和本次位置连线的方位角
        // if (!prePosition.equals(position)) {
        let ang = 0;
        // let ang = this.bearing(prePosition, position);
        // console.log(ang, 'ang');
        let h = Cesium.Math.toRadians(0);
        let p = Cesium.Math.toRadians(0);
        // h = window.yaw;
        let orientation = Cesium.Transforms.headingPitchRollQuaternion(
          position,
          new Cesium.HeadingPitchRoll(h, p, 0)
        );
        // console.log(orientation, 'orientation');
        // let hpr = new Cesium.HeadingPitchRoll(window.yaw, window.pitch, window.roll);
        // orientation = Cesium.Transforms.headingPitchRollQuaternion(position, hpr)
        prePosition = position;
        return orientation;
        // }
      }, false);
      // lower_rotar_Entity.orientation = a;
      // upper_rotar_Entity.orientation = a;
      bodyEntity.orientation = b;
      window.bodyEntity = bodyEntity;
      return body;
    },
    // 加载单个Q20
    add_model_Q20_single(entities, data, uri) {
      return entities.add({
        id: data.entity_id,
        parent: data.parent || null,
        orientation: new Cesium.CallbackProperty(() => data.orientation, false),
        // position: new Cesium.CallbackProperty(() => data.position, false),
        position: data.position,
        model: {
          uri: uri,
          minimumPixelSize: 100,
          maximumScale: 10000,
          scale: 25,
          ...(data.options ? data.options : {}),
          // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
        },
      });
    },

    // 添加550模型
    add_model_550(entities, data, uriArray) {
      // alert(111)
      let bodyEntity = new Cesium.Entity({
        id: data.entity_id,
        // name: data.entity_id + '_body',
        parent: data.parent || null,
        orientation: new Cesium.CallbackProperty(() => data.orientation, false),
        // orientation: new Cesium.CallbackProperty(() => {
        //   return data.orientation;
        // }, false),
        // position: new Cesium.CallbackProperty(() => data.position, false),
        position: data.position,
        model: {
          uri: uriArray[0],
          minimumPixelSize: 100,
          maximumScale: 10000,
          scale: 100,
          ...(data.options ? data.options : {}),
        },
      });
      // let lower_rotar_Entity = new Cesium.Entity({
      //   id: data.entity_id + '_lower_rotar',
      //   parent: data.parent || null,
      //   orientation: new Cesium.CallbackProperty(() => {
      //     return data.orientation
      //   }, false),
      //   position: new Cesium.CallbackProperty(() => data.position, false),
      //   model: {
      //     uri: uriArray[1],
      //     minimumPixelSize: 100,
      //     maximumScale: 10000,
      //     scale: 1,
      //     ...(data.options ? data.options : {})
      //   }
      // })
      // let upper_rotar_Entity = new Cesium.Entity({
      //   id: data.entity_id + '_upper_rotar',
      //   parent: data.parent || null,
      //   orientation: new Cesium.CallbackProperty(() => {
      //     return data.orientation
      //   }, false),
      //   position: new Cesium.CallbackProperty(() => data.position, false),
      //   model: {
      //     uri: uriArray[2],
      //     minimumPixelSize: 100,
      //     maximumScale: 10000,
      //     scale: 1,
      //     ...(data.options ? data.options : {})
      //   }
      // })
      let body = entities.add(bodyEntity);
      // entities.add(lower_rotar_Entity)
      // entities.add(upper_rotar_Entity)
      let headerFlag = 0;
      // let prePosition = lower_rotar_Entity.position.getValue();
      // function loop () {
      //   console.log(body.show);
      //   if (body.show) {
      //     let position1 = lower_rotar_Entity.position.getValue();//求出当前事件entity的位置
      //     let position2 = upper_rotar_Entity.position.getValue();//求出当前事件entity的位置
      //     let h = Cesium.Math.toRadians(headerFlag);
      //     let orientation1 = Cesium.Transforms.headingPitchRollQuaternion(position1, new Cesium.HeadingPitchRoll(h, 0, 0));
      //     let orientation2 = Cesium.Transforms.headingPitchRollQuaternion(position2, new Cesium.HeadingPitchRoll(h, 0, 0));
      //     lower_rotar_Entity.orientation = orientation1;
      //     upper_rotar_Entity.orientation = orientation2;
      //     headerFlag += 2;
      //     if (headerFlag >= 360) headerFlag = 0;
      //   }
      //   requestAnimationFrame(loop);
      // }
      // loop();
      // let a = new Cesium.CallbackProperty((result, time) => {
      //   headerFlag += 2
      //   if (headerFlag >= 360) headerFlag = 0
      //   let position = bodyEntity.position.getValue() //求出当前时间entity的位置
      //   let h = Cesium.Math.toRadians(headerFlag)
      //   let orientation = Cesium.Transforms.headingPitchRollQuaternion(position, new Cesium.HeadingPitchRoll(h, 0, 0))
      //   return orientation
      // }, false)
      // 当前位置
      // let prePosition = bodyEntity.position.getValue() //求出当前时间entity的位置
      // let b = new Cesium.CallbackProperty((result, time) => {
      //   let position = bodyEntity.position.getValue() //求出当前时间entity的位置
      //   // 求解上一次位置和本次位置连线的方位角
      //   // if (!prePosition.equals(position)) {
      //   let ang = 0
      //   // let ang = this.bearing(prePosition, position);
      //   // console.log(ang, 'ang');
      //   let h = 0
      //   let hh = window.yaw || 0
      //   hh = hh + 90
      //   h = (hh * Math.PI) / 180
      //   let orientation = Cesium.Transforms.headingPitchRollQuaternion(position, new Cesium.HeadingPitchRoll(h, 0, 0))
      //   prePosition = position
      //   return orientation
      //   // }
      // }, false)
      // lower_rotar_Entity.orientation = a
      // upper_rotar_Entity.orientation = a
      // bodyEntity.orientation = b
      return body;
    },

    // 添加六旋翼
    add_model_six(entities, data, uri) {
      return entities.add({
        id: data.entity_id,
        parent: data.parent || null,
        orientation: new Cesium.CallbackProperty(() => data.orientation, false),
        position: new Cesium.CallbackProperty(() => data.position, false),
        model: {
          uri: uri,
          // uri: "cesium/model/1800_20210316_no_effect283g2color.gltf",
          // uri: "cesium/model/mmcModel2.gltf",
          // uri: "cesium/model/drone_costum.glb",
          minimumPixelSize: 100,
          maximumScale: 10000,
          // scale: 1.5,
          scale: 10,
          ...(data.options ? data.options : {}),
          // show: new Cesium.CallbackProperty(
          //   () => !window.plate_data_source || !window.plate_data_source.show,
          //   false
          // ),
        },
      });
    },

    add_model_X85(entities, data) {
      return entities.add({
        id: data.entity_id,
        parent: data.parent || null,
        orientation: new Cesium.CallbackProperty(() => data.orientation, false),
        position: new Cesium.CallbackProperty(() => data.position, false),
        model: {
          uri: "cesium/model/x85_0311.gltf",
          minimumPixelSize: 50,
          maximumScale: 1000,
          scale: 1,
          ...(data.options ? data.options : {}),
          // show: new Cesium.CallbackProperty(
          //   () => !window.plate_data_source || !window.plate_data_source.show,
          //   false
          // ),
        },
      });
    },
    // 添加航线
    add_polyline(entities, data) {
      console.log("datadatadatadata", data);

      return viewer.entities.add({
        id: data.entity_id,
        parent: data.parent || null,
        polyline: {
          positions: new Cesium.CallbackProperty(() => {
            return data.positions;
          }, false),
          // positions: data.positions,
          width: 6,
          show: true,
          material: Cesium.Color.fromCssColorString("#6DD400"),
          ...(data.options ? data.options : {}),
        },
      });
    },
    // 计算方位角 返回单位 度 °
    bearing(p0, p1) {
      // p0 = new Cesium.Cartesian3.fromDegrees(118, 10, 10000);
      // p1 = new Cesium.Cartesian3.fromDegrees(118, 30, 10000);
      let p00 = this.c3toDegrees(p0);
      let p01 = this.c3toDegrees(p1);

      console.log(p00, "per");
      console.log(p01);

      let startLat = p00["lat"];
      let startLng = p00["lon"];
      let destLat = p01["lat"];
      let destLng = p01["lon"];
      const y = Math.sin(destLng - startLng) * Math.cos(destLat);
      const x =
        Math.cos(startLat) * Math.sin(destLat) -
        Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
      const brng = Math.atan2(y, x);
      const brngDgr = Cesium.Math.toDegrees(brng);
      console.log(brngDgr);
      console.log((brngDgr + 360) % 360);
      // debugger
      return (brngDgr + 360) % 360;
    },
    // 笛卡尔坐标转经纬度
    c3toDegrees(c3) {
      //笛卡尔世界坐标转经纬度弧度坐标
      let cartographic =
        window.viewer.scene.globe.ellipsoid.cartesianToCartographic(c3);
      //经纬度弧度坐标转经纬度坐标
      let lon = Cesium.Math.toDegrees(cartographic.longitude);
      let lat = Cesium.Math.toDegrees(cartographic.latitude);
      let height = cartographic.height;
      return { lon: lon, lat: lat, height: height };
    },
    // 添加网格
    addGrids(gridsData) {
      try {
        window.viewer.scene.postProcessStages.fxaa.enabled = true;
        let polylineGeomInstanceArr = [];
        let polygonGeomInstanceArr = [];
        let len = gridsData.length;
        for (let i = 0; i < len; i++) {
          gridsData[i].h = 0;
          // 矩形
          let polygon = new Cesium.PolygonGeometry({
            polygonHierarchy: new Cesium.PolygonHierarchy(
              Cesium.Cartesian3.fromDegreesArray([
                gridsData[i].l,
                gridsData[i].b,
                gridsData[i].r,
                gridsData[i].b,
                gridsData[i].r,
                gridsData[i].t,
                gridsData[i].l,
                gridsData[i].t,
              ])
            ),
            height: 0, //设置默认为0，禁飞区为8 让禁飞区在网格码上面
          });
          let polygonGeometry = Cesium.PolygonGeometry.createGeometry(polygon);
          let polygonGeomInstance = new Cesium.GeometryInstance({
            geometry: polygonGeometry,
            attributes: {
              color: Cesium.ColorGeometryInstanceAttribute.fromColor(
                Cesium.Color.fromCssColorString("#9CF43F").withAlpha(0.2)
              ),
            },
          });
          // 多段线，边框
          let polylineGeometry = new Cesium.PolylineGeometry({
            positions: Cesium.Cartesian3.fromDegreesArray([
              gridsData[i].l,
              gridsData[i].b,
              gridsData[i].r,
              gridsData[i].b,
              gridsData[i].r,
              gridsData[i].t,
              gridsData[i].l,
              gridsData[i].t,
              gridsData[i].l,
              gridsData[i].b,
            ]),
            width: 0.6,
          });
          let polylineGeomInstance = new Cesium.GeometryInstance({
            geometry: polylineGeometry,
            attributes: {
              color: Cesium.ColorGeometryInstanceAttribute.fromColor(
                Cesium.Color.fromCssColorString("#89BE51").withAlpha(1.0)
              ),
            },
          });
          polylineGeomInstanceArr.push(polylineGeomInstance);
          polygonGeomInstanceArr.push(polygonGeomInstance);
        }
        if (this.polylinePrimitive) {
          window.viewer.scene.primitives.remove(this.polylinePrimitive);
        }
        if (this.polygonPrimitive) {
          window.viewer.scene.primitives.remove(this.polygonPrimitive);
        }
        this.polylinePrimitive = new Cesium.Primitive({
          geometryInstances: polylineGeomInstanceArr,
          vertexFormat: Cesium.PolylineColorAppearance.VERTEX_FORMAT,
          appearance: new Cesium.PolylineColorAppearance({
            aboveGround: true,
            translucent: true,
          }),
        });
        this.polygonPrimitive = new Cesium.Primitive({
          geometryInstances: polygonGeomInstanceArr,
          vertexFormat: Cesium.PolylineColorAppearance.VERTEX_FORMAT,
          appearance: new Cesium.PolylineColorAppearance({
            aboveGround: true,
            translucent: true,
          }),
        });
        window.viewer.scene.primitives.add(this.polylinePrimitive);
        window.viewer.scene.primitives.add(this.polygonPrimitive);
        window.viewer.scene.primitives.lowerToBottom(this.polylinePrimitive);
        window.viewer.scene.primitives.lowerToBottom(this.polygonPrimitive);
      } catch (e) {
        console.log(e, "viewer还没初始化");
      }
    },
    // 初始化定位到芜湖
    async initLocation2WUHU() {
      // 初始化定位芜湖市！！！
      // await window.viewer.camera.flyTo({
      //   // destination: Cesium.Cartesian3.fromDegrees(120.218241, 33.165767, 21812.321476599747),
      //   destination: Cesium.Cartesian3.fromDegrees(8.22073, 29.50576, 151812.321476599747),
      //   orientation: {
      //     heading: 0.006918732929549698,
      //     pitch: -0.6928665494487145,
      //     roll: 7.638334409421077e-14
      //   },
      //   time: 5
      // })
    },
    // 添加芜湖消防支队(WHXFZD)影像图
    addWHXFZDImageLayer() {
      // return
      if (whimgerLayer) {
        return;
      }
      let xyzProvider = new Cesium.TileMapServiceImageryProvider({
        url: WHXFZDIMGURL,
      });
      whimgerLayer =
        window.viewer.imageryLayers.addImageryProvider(xyzProvider);
      // window.viewer.flyTo(ly);
      let pos = new Cesium.Cartesian3.fromDegrees(
        118.468883104271,
        31.3557784225995,
        0
      );
      // 添加标记点，芜湖消防支队
      let en = new Cesium.Entity({
        name: "whxfzd_point",
        position: pos,
        label: {
          text: "芜湖消防支队",
          show: true,
          showBackground: false,
          font: "14px monospace bolder",
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          pixelOffset: new Cesium.Cartesian2(0, -15),
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          disableDepthTestDistance: 99000000,
          fillColor: Cesium.Color.fromCssColorString("#92D9FF"), // 字体颜色
          translucencyByDistance: new Cesium.NearFarScalar(1000, 0, 100000, 1),
        },
        billboard: {
          show: true,
          image: require("@/assets/zong_lan_ye_img/home_pt_location.png"),
          pixelOffset: new Cesium.Cartesian2(0, 0),
          width: 31,
          height: 40,
          scale: 0.7,
          translucencyByDistance: new Cesium.NearFarScalar(1000, 0, 100000, 1),
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          disableDepthTestDistance: 99000000,
        },
      });
      whPoint = window.viewer.entities.add(en);
    },
    // 湾沚南湖印
    loadWZNHY3DTiles() {
      // return
      let tilesDataSource = new Cesium.Cesium3DTileset({
        url: WZNHYIMGURL,
        // debugShowMemoryUsage: false,
        // debugColorizeTiles: false,
        // debugShowUrl: false,
        // imageBasedLightingFactor: Cesium.Cartesian2(0.0, 0.0),
        // maximumScreenSpaceError: 2, //最大的屏幕空间误差
        // maximumNumberOfLoadedTiles: 1000 //最大加载瓦片个数
        // maximumMemoryUsage: 1024 * 1024 * 1024,
        // lightColor: lightColor,
      });
      tilesDataSource.readyPromise.then((tileset) => {
        let cartographic = Cesium.Cartographic.fromCartesian(
          tileset.boundingSphere.center
        );
        let surface = Cesium.Cartesian3.fromRadians(
          cartographic.longitude,
          cartographic.latitude,
          cartographic.height
        );
        let offset = Cesium.Cartesian3.fromRadians(
          cartographic.longitude,
          cartographic.latitude,
          55
        );
        let translation = Cesium.Cartesian3.subtract(
          offset,
          surface,
          new Cesium.Cartesian3()
        );
        tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
        window.viewer.scene.primitives.add(tileset);
        // window.viewer.zoomTo(tileset);

        let en = new Cesium.Entity({
          name: "wznhjd_point",
          position: tileset.boundingSphere.center,
          label: {
            text: "湾沚南湖印",
            show: true,
            showBackground: false,
            font: "14px monospace bolder",
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            pixelOffset: new Cesium.Cartesian2(0, -15),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            fillColor: Cesium.Color.fromCssColorString("#92D9FF"), // 字体颜色
            translucencyByDistance: new Cesium.NearFarScalar(
              1000,
              0,
              100000,
              1
            ),
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            disableDepthTestDistance: 99000000,
          },
          billboard: {
            show: true,
            image: require("@/assets/zong_lan_ye_img/home_pt_location.png"),
            pixelOffset: new Cesium.Cartesian2(0, 0),
            width: 31,
            height: 40,
            scale: 0.7,
            translucencyByDistance: new Cesium.NearFarScalar(
              1000,
              0,
              100000,
              1
            ),
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            disableDepthTestDistance: 99000000,
          },
        });

        // let en = new Cesium.Entity({
        //   position: tileset.boundingSphere.center,
        //   point: {
        //     pixelSize: 20,
        //     color: Cesium.Color.YELLOW,
        //   }
        // });
        // console.log(tileset.boundingSphere.center, 'tileset.boundingSphere.center');
        window.viewer.entities.add(en);
      });
    },
    // 添加几个点  酒吧 会客厅 供销社 美学馆
    addPointTemporary() {
      // 酒吧 ：118.631622, 31.245998
      // 会客厅 ：118.6342081, 31.2487858
      // 供销社 ：118.6332589, 31.2543626
      // 美学馆 ：118.6370295, 31.2491129
      let data = [
        { name: "酒吧", longitude: 118.631622, latitude: 31.245998 },
        { name: "会客厅", longitude: 118.6342081, latitude: 31.2487858 },
        { name: "供销社", longitude: 118.6332589, latitude: 31.2543626 },
        { name: "美学馆", longitude: 118.6370295, latitude: 31.2491129 },
      ];
      data.forEach((item) => {
        let en = new Cesium.Entity({
          position: Cesium.Cartesian3.fromDegrees(
            item.longitude,
            item.latitude,
            0
          ),
          point: {
            pixelSize: 14,
            color: Cesium.Color.YELLOW,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
          },
          label: {
            text: item.name,
            show: true,
            showBackground: false,
            font: "10px monospace bolder",
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            pixelOffset: new Cesium.Cartesian2(0, -20),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            // disableDepthTestDistance: 99000000,
            fillColor: Cesium.Color.fromCssColorString("#FFFF00"), // 字体颜色
            translucencyByDistance: new Cesium.NearFarScalar(
              3000,
              1,
              100000,
              0
            ),
          },
        });
        window.viewer.entities.add(en);
      });
    },
    /**@Description : 添加深圳市geojson面数据
     *@Date : 2024-01-24 11:13:03
     */
    init_shenzhen_polygon() {
      // return;
      // console.log(WuHuGeoJsonBorder, 'WuHuGeoJsonBorderWuHuGeoJsonBorder');
      let promise = Cesium.GeoJsonDataSource.load(ShenZhenGeoJsonPolygon);
      // let dataSource = promise;
      promise.then((dataSource) => {
        let entities = dataSource.entities.values;
        for (let i = 0; i < entities.length; i++) {
          let entity = entities[i];
          entity._name = "polygon" + "_" + entity._name + "_shenzhen";
          entity.polygon.outline = true;
          entity.polygon.fill = true;
          entity.polygon.material =
            Cesium.Color.fromCssColorString("#9CF43F").withAlpha(0.2);
          entity.polygon.outlineColor =
            Cesium.Color.fromCssColorString("#ffd33c");
          let positions = entity.polygon.hierarchy._value.positions;
          entity.polyline = {
            positions: positions.map((item) => {
              let c = Cesium.Cartesian3.clone(item);
              let wgs84 = Utils.transformCartesian2WGS84(c);
              return Utils.transformWGS842Cartesian(wgs84);
            }),
            width: 4,
            material: Cesium.Color.fromCssColorString("#36b558"),
            clampToGround: true,
            // material: Cesium.Color.fromCssColorString('#ffb319'),
          };
        }
        viewer.dataSources.add(dataSource);
        dataSource.show = true;
        border_data_source_shenzhen = dataSource;
      });
    },
    /**@Description :  ******************************************* 加载新的空域列表
     **@Date: 2024-03-27 09:39:07
     */
    async addNewFlightArea() {
      let res = await API.AIRSPACE.getFlightSpaceGetAreas();
      // console.log('加载新的空域列表', res);
      for (let i = 0; i < res.length; i++) {
        let item = res[i];
        let entityPolygon = new Cesium.Entity({
          id: String(item.id),
          name: "entityPolygon",
          polygon: {
            hierarchy: Cesium.Cartesian3.fromDegreesArray(item.regionList),
            material: Cesium.Color.fromCssColorString(item.color).withAlpha(
              0.2
            ),
            extrudedHeight: 0,
            // closeTop: false,
            // closeBottom: false,
            outline: true,
            outlineColor: Cesium.Color.fromCssColorString(item.color),
          },
        });
        window.viewer.entities.add(entityPolygon);
      }
    },
    /**@Description :  ******************************************* shenzhenLonggangLayer
     **@Date: 2024-03-28 09:30:42
     */
    addShenzhenLonggangLayer() {
      // return
      if (shenzhenLonggangLayer) {
        return;
      }
      let xyzProvider = new Cesium.TileMapServiceImageryProvider({
        url: shenzhenLonggangLayerUrl,
      });
      shenzhenLonggangLayer =
        window.viewer.imageryLayers.addImageryProvider(xyzProvider);

      // window.viewer.flyTo(shenzhenLonggangLayer);
      // let pos = new Cesium.Cartesian3.fromDegrees(118.468883104271, 31.3557784225995, 0)
      // 添加标记点，芜湖消防支队
      // let en = new Cesium.Entity({
      //   name: 'whxfzd_point',
      //   position: pos,
      //   label: {
      //     text: '芜湖消防支队',
      //     show: true,
      //     showBackground: false,
      //     font: '14px monospace bolder',
      //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
      //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      //     pixelOffset: new Cesium.Cartesian2(0, -15),
      //     disableDepthTestDistance: Number.POSITIVE_INFINITY,
      //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      //     disableDepthTestDistance: 99000000,
      //     fillColor: Cesium.Color.fromCssColorString('#92D9FF'), // 字体颜色
      //     translucencyByDistance: new Cesium.NearFarScalar(1000, 0, 100000, 1)
      //   },
      //   billboard: {
      //     show: true,
      //     image: require('@/assets/zong_lan_ye_img/home_pt_location.png'),
      //     pixelOffset: new Cesium.Cartesian2(0, 0),
      //     width: 31,
      //     height: 40,
      //     scale: 0.7,
      //     translucencyByDistance: new Cesium.NearFarScalar(1000, 0, 100000, 1),
      //     disableDepthTestDistance: Number.POSITIVE_INFINITY,
      //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      //     disableDepthTestDistance: 99000000
      //   }
      // })
      // whPoint = window.viewer.entities.add(en)
    },
    /**@Description :  ******************************************* shiyanMaojianLayer
     **@Date: 2024-03-28 09:49:05
     */
    addShiyanMaojianLayer() {
      // return
      if (shiyanMaojianLayer) {
        return;
      }
      let xyzProvider = new Cesium.TileMapServiceImageryProvider({
        url: shiyanMaojianLayerUrl,
      });
      shiyanMaojianLayer =
        window.viewer.imageryLayers.addImageryProvider(xyzProvider);
      // window.viewer.flyTo(shiyanMaojianLayer);
    },
    /**@Description :  ******************************************* wuhuWanzhiLayer
     **@Date: 2024-03-29 09:20:55
     */
    addWuhuWanzhiLayer() {
      // return
      if (wuhuWanzhiLayer) {
        return;
      }
      let xyzProvider = new Cesium.TileMapServiceImageryProvider({
        url: wuhuWanzhiLayerUrl,
      });
      wuhuWanzhiLayer =
        window.viewer.imageryLayers.addImageryProvider(xyzProvider);
      // window.viewer.imageryLayers.lowerToBottom(wuhuWanzhiLayer);
      // window.viewer.flyTo(wuhuWanzhiLayer);
    },
    /**@Description :  ******************************************* zhengzhouJinshuiLayerUrl
     **@Date: 2024-03-28 09:53:49  HT1212202466115995
     */
    addZhengzhouJinshuiLayer() {
      // return
      if (zhengzhouJinshuiLayer) {
        return;
      }
      let xyzProvider = new Cesium.TileMapServiceImageryProvider({
        url: zhengzhouJinshuiLayerUrl,
      });
      zhengzhouJinshuiLayer =
        window.viewer.imageryLayers.addImageryProvider(xyzProvider);
    },
    /**@Description :  ******************************************* zhengzhouZhongyuanLayer
     **@Date: 2024-03-29 09:23:40
     */
    addZhengzhouZhongyuanLayer() {
      // return
      if (zhengzhouZhongyuanLayer) {
        return;
      }
      let xyzProvider = new Cesium.TileMapServiceImageryProvider({
        url: zhengzhouZhongyuanLayerUrl,
      });
      zhengzhouZhongyuanLayer =
        window.viewer.imageryLayers.addImageryProvider(xyzProvider);
      // window.viewer.flyTo(zhengzhouZhongyuanLayer);
    },
    /**@Description :  ******************************************* 添加故宫-测试
     **@Date: 2024-04-15 11:17:53
     */
    addGG() {
      let ggLayerUrl = "https://ualins.uatair.com/mapSource/gugong_test_4326";
      let xyzProvider = new Cesium.TileMapServiceImageryProvider({
        url: ggLayerUrl,
      });
      window.viewer.imageryLayers.addImageryProvider(xyzProvider);
    },
    /**@Description :  ******************************************* 芜湖顺德影像加载
     **@Date: 2024-04-16 09:04:00
     */
    addFoShanShunDeLayer() {
      if (foshanShunDeLayer) {
        return;
      }
      let xyzProvider = new Cesium.TileMapServiceImageryProvider({
        url: foshanShunDeLayerUrl,
      });
      foshanShunDeLayer =
        window.viewer.imageryLayers.addImageryProvider(xyzProvider);
    },
    /**@Description :  ******************************************* 加载空域线
     **@Date: 2024-04-16 14:41:56
     */
    async addAirSpaceLine() {
      // let res = await API.AIRWAY.getAirspaceLineList();
      // console.log('加载空域线', res);
    },
    /**@Description :  ******************************************* 加载深圳基地模型
     **@Date: 2024-05-13 21:05:38
     */
    async addShenZhenJDDeLayer1() {
      let tilesDataSource = new Cesium.Cesium3DTileset({
        url: shenZhenJDLayerUrl,
      });
      tilesDataSource.readyPromise.then((tileset) => {
        window.viewer.scene.primitives.add(tileset);
        // setTimeout(() => {
        //   window.viewer.flyTo(tileset);
        // }, 10000);
      });
      // setTimeout(async () => {
      //   let tObj = await Cesium.Cesium3DTileset.fromUrl(
      //       'https://ualins.uatair.com/mapSource/output-shenzhen-20240606/tileset.json', {
      //         // skipLevelOfDetail: true,
      //         // baseScreenSpaceError: 1024,
      //         // skipScreenSpaceErrorFactor: 16,
      //         // skipLevels: 1,
      //         // immediatelyLoadDesiredLevelOfDetail: false,
      //         // loadSiblings: false,
      //         // cullWithChildrenBounds: true
      //         skipLevelOfDetail: true,
      //         baseScreenSpaceError: 10,
      //         maxScreenSpaceError: 50,
      //         preload: 0,
      //         implicitTileParents: false,
      //         optimizeMemory: true
      //       }
      //   );
      //   let tileset = window.viewer.scene.primitives.add(tObj);
      //   window.viewer.scene.globe.depthTestAgainstTerrain = true;
      //   tileset.readyPromise.then(function (tileset) {
      //     let boundingSphere = tileset.boundingSphere; // 模型的范围
      //     let cartographic = Cesium.Cartographic.fromCartesian(boundingSphere.center); // 从笛卡尔位置创建一个新的位置实例（返回的是一个经纬度的）
      //     cartographic.longitude = 118;
      //     cartographic.latitude = 32;
      //     let surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 5000000000.0); // 以经纬度的值来返回Cartesian3(xyz)的位置 ，/
      //     let offset = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 5000000000); // 以经纬度的值来返回Cartesian3(xyz)的位置 ，//>
      //     let translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3()); // 计算两个笛卡尔的分量差异。
      //     console.log('translation', translation);
      //
      //     tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
      //     console.log('tileset', tileset);
      //     window.tileset = tileset;
      //     window.viewer.flyTo(tileset);
      //   });
      //
      // }, 10000);
    },
    async addShenZhenJDDeLayer2() {
      // let tiles = window.viewer.scene.primitives.add(new Cesium.Cesium3DTileset({
      //   // url: 'https://ualins.uatair.com/mapSource/output-shenzhen-20240623/tileset.json',
      //   // url: 'https://ualins.uatair.com/mapSource/output-shenzhen-20240702/tileset.json',
      //   url: 'https://ualins.uatair.com/mapSource/output-shenzhen-20240709/finaltest/tileset.json',
      //   // maximnumNumberOfLoadedTiles: 100,
      // }));
      return;
      let tilesDataSource = new Cesium.Cesium3DTileset({
        url: "https://ualins.uatair.com/mapSource/output-shenzhen-20240623/tileset.json",
        show: true,
        // 最大的屏幕空间误差，数值越大，需要靠的越近才能渲染，性能越小
        // maximumScreenSpaceError: 128,
        // 最大的GPU内存
        // maximumMemoryUsage: 512,
        // shadows: Cesium.ShadowMode.DISABLED,
        // skipLevelOfDetail: true,
        // baseScreenSpaceError: 128,
        // skipScreenSpaceErrorFactor: 64,
        // skipLevels: 1,
        // immediatelyLoadDesiredLevelOfDetail: false,
        // loadSiblings: false,
        // cullWithChildrenBounds: true
      });
      // window.viewer.scene.primitives.add(tilesDataSource);
      // window.tileset = tilesDataSource;
      // window.viewer = viewer.value;
      tilesDataSource.readyPromise.then((palaceTileset) => {
        window.viewer.scene.primitives.add(palaceTileset);
        window.tileset = palaceTileset;
        let longitude = 113.0; //模型需要改变的经度
        let latitude = 28.0; //模型需要改变的纬度
        let heightOffset = 20.0; //模型需要改变的高度
        window.viewer.flyTo(palaceTileset);
        // window.viewer.flyTo(window.tileset);

        // let position = Cesium.Cartesian3.fromDegrees(longitude, latitude); // 纬度, 经度, 高度
        // let hpr = new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(0.0), Cesium.Math.toRadians(-90.0), 0.0);
        // let transform = Cesium.Transforms.headingPitchRollToFixedFrame(position, hpr, Cesium.Ellipsoid.WGS84);

        // 设置tileset的modelMatrix
        // palaceTileset.modelMatrix = transform;

        // let cartographic = Cesium.Cartographic.fromCartesian(palaceTileset.boundingSphere.center);
        // let surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, cartographic.height);
        // let offset = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, heightOffset);
        // let translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3());
        // palaceTileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);

        // window.viewer.zoomTo(palaceTileset, new Cesium.HeadingPitchRange(0.5, -0.2, palaceTileset.boundingSphere.radius * 1.0));
      });
      // await window.viewer.zoomTo(tilesDataSource);
      // tilesDataSource.readyPromise.then((tileset) => {
      //   window.viewer.scene.primitives.add(tileset)
      // })
      // setTimeout(async () => {
      //   let tObj = await Cesium.Cesium3DTileset.fromUrl(
      //       'https://ualins.uatair.com/mapSource/output-shenzhen-20240606/tileset.json', {
      //         // skipLevelOfDetail: true,
      //         // baseScreenSpaceError: 1024,
      //         // skipScreenSpaceErrorFactor: 16,
      //         // skipLevels: 1,
      //         // immediatelyLoadDesiredLevelOfDetail: false,
      //         // loadSiblings: false,
      //         // cullWithChildrenBounds: true
      //         skipLevelOfDetail: true,
      //         baseScreenSpaceError: 10,
      //         maxScreenSpaceError: 50,
      //         preload: 0,
      //         implicitTileParents: false,
      //         optimizeMemory: true
      //       }
      //   );
      //   let tileset = window.viewer.scene.primitives.add(tObj);
      //   window.viewer.scene.globe.depthTestAgainstTerrain = true;
      //   tileset.readyPromise.then(function (tileset) {
      //     let boundingSphere = tileset.boundingSphere; // 模型的范围
      //     let cartographic = Cesium.Cartographic.fromCartesian(boundingSphere.center); // 从笛卡尔位置创建一个新的位置实例（返回的是一个经纬度的）
      //     cartographic.longitude = 118;
      //     cartographic.latitude = 32;
      //     let surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 5000000000.0); // 以经纬度的值来返回Cartesian3(xyz)的位置 ，/
      //     let offset = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 5000000000); // 以经纬度的值来返回Cartesian3(xyz)的位置 ，//>
      //     let translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3()); // 计算两个笛卡尔的分量差异。
      //     console.log('translation', translation);
      //
      //     tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
      //     console.log('tileset', tileset);
      //     window.tileset = tileset;
      //     window.viewer.flyTo(tileset);
      //   });
      //
      // }, 10000);
    },
    async addShenZhenJDDeLayer() {
      // viewer.scene.globe.enableLighting = true; // 启用地球的光照
      // 设置光照和环境参数
      viewer.scene.light = new Cesium.DirectionalLight({
        direction: new Cesium.Cartesian3(-1.0, -1.0, -1.0),
        intensity: 3.0,
      });
      let tObj = await Cesium.Cesium3DTileset.fromUrl(
        "https://ualins.uatair.com/mapSource/output-shenzhen-20240709/finaltest/tileset.json",
        {
          // skipLevelOfDetail: true,
          // baseScreenSpaceError: 1024,
          // skipScreenSpaceErrorFactor: 16,
          // skipLevels: 1,
          // immediatelyLoadDesiredLevelOfDetail: false,
          // loadSiblings: false,
          // cullWithChildrenBounds: true
          // skipLevelOfDetail: true,
          // baseScreenSpaceError: 10,
          // maxScreenSpaceError: 50,
          // preload: 0,
          // implicitTileParents: false,
          // optimizeMemory: true
        }
      );
      let tileset = window.viewer.scene.primitives.add(tObj);
      window.viewer.scene.globe.depthTestAgainstTerrain = true;
      tileset.readyPromise.then(function (tileset) {
        let boundingSphere = tileset.boundingSphere; // 模型的范围
        let cartographic = Cesium.Cartographic.fromCartesian(
          boundingSphere.center
        ); // 从笛卡尔位置创建一个新的位置实例（返回的是一个经纬度的）
        // cartographic.longitude = 118;
        // cartographic.latitude = 32;
        // console.log('cartographic.longitude, cartographic.latitude, cartographic.height', cartographic.longitude, cartographic.latitude, cartographic.height);
        let surface = Cesium.Cartesian3.fromRadians(
          cartographic.longitude,
          cartographic.latitude,
          cartographic.height
        ); // 以经纬度的值来返回Cartesian3(xyz)的位置 ，/
        // let offset = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 1000); // 以经纬度的值来返回Cartesian3(xyz)的位置 ，//>
        // 关于模型位置参数说明，何博士给出的经纬度无法与真实地物对应，位置参数进行了手动微调，但应在模型生成时打地面像控点解决！！!!
        let offset = Cesium.Cartesian3.fromRadians(
          (114.230390037969 / 180) * Math.PI,
          (22.656865 / 180) * Math.PI,
          cartographic.height * 1.18
        ); // 以经纬度的值来返回Cartesian3(xyz)的位置 ，//>
        let translation = Cesium.Cartesian3.subtract(
          offset,
          surface,
          new Cesium.Cartesian3()
        ); // 计算两个笛卡尔的分量差异。
        // console.log('translation', translation);
        tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
        // console.log('tileset', tileset);
        // window.tileset = tileset;
        // window.viewer.flyTo(tileset);
      });

      const origin = {
        longitude: 114.2301300327969,
        latitude: 22.657077125882356,
        height: 10.0,
      };
      this.drawMeshInCesium(ShenZhenGridJson, origin);
    },
    drawMeshInCesium(vertices, origin) {
      const gridSpacing = 30; // 将格子的间距调整为30米

      // 获取所有顶点的范围
      const bounds = this.getBounds(vertices);

      // 创建透明的立方体网格
      this.createGridMesh(window.viewer, vertices, origin, bounds, gridSpacing);

      // 设置视图范围以确保能看到模型
      viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(
          origin.longitude,
          origin.latitude,
          5000 // 设置较大的高度以确保模型和网格可见
        ),
        orientation: {
          heading: Cesium.Math.toRadians(0.0),
          pitch: Cesium.Math.toRadians(-30.0),
          roll: Cesium.Math.toRadians(0.0),
        },
      });
    },
    createGridMesh(viewer, vertices, origin, bounds, gridSpacing) {
      const minX = bounds.minX;
      const maxX = bounds.maxX;
      const minY = bounds.minY;
      const maxY = bounds.maxY;

      const primitives = new Cesium.PrimitiveCollection();

      for (let x = minX; x <= maxX; x += gridSpacing) {
        for (let y = minY; y <= maxY; y += gridSpacing) {
          // 找到该网格单元中的所有点
          const pointsInCell = vertices.filter(
            (v) =>
              Math.abs(v.x - x) < gridSpacing / 2 &&
              Math.abs(v.y - y) < gridSpacing / 2
          );

          // 计算该网格单元的最大高度
          const maxZ =
            pointsInCell.length > 0
              ? Math.max(...pointsInCell.map((v) => v.z))
              : 0;
          // console.log("Cell:", { x, y, maxZ }); // 调试信息

          // 创建立方体的高度
          const cubeHeight = maxZ - origin.height;

          if (cubeHeight > 0) {
            // 创建几何体
            const cubeGeometry = new Cesium.BoxGeometry({
              maximum: new Cesium.Cartesian3(
                gridSpacing / 2,
                gridSpacing / 2,
                cubeHeight
              ),
              minimum: new Cesium.Cartesian3(
                -gridSpacing / 2,
                -gridSpacing / 2,
                0
              ),
            });

            const geometryInstance = new Cesium.GeometryInstance({
              geometry: cubeGeometry,
              modelMatrix: Cesium.Transforms.eastNorthUpToFixedFrame(
                Cesium.Cartesian3.fromDegrees(
                  origin.longitude + x / 111320, // 经度调整
                  origin.latitude + y / 111320, // 纬度调整
                  origin.height
                )
              ),
              attributes: {
                color: Cesium.ColorGeometryInstanceAttribute.fromColor(
                  Cesium.Color.YELLOW.withAlpha(0.3) // 透明绿色填充
                ),
              },
            });

            const appearance = new Cesium.PerInstanceColorAppearance({
              flat: true,
              translucent: true,
            });

            primitives.add(
              new Cesium.Primitive({
                geometryInstances: geometryInstance,
                appearance: appearance,
              })
            );
          }
        }
      }

      viewer.scene.primitives.add(primitives);
    },
    // 抽稀算法
    thinVertices(vertices, step) {
      const thinned = [];
      for (let i = 0; i < vertices.length; i += step) {
        thinned.push(vertices[i]);
      }
      return thinned;
    },
    getBounds(vertices) {
      const xCoords = vertices.map((v) => v.x);
      const yCoords = vertices.map((v) => v.y);
      return {
        minX: Math.min(...xCoords),
        maxX: Math.max(...xCoords),
        minY: Math.min(...yCoords),
        maxY: Math.max(...yCoords),
      };
    },
    /**@Description :  ******************************************* 添加东台起降场
     **@Date: 2024-08-13 18:51:18
     */
    addDTPoint() {
      if (this.$store.state.user.user_info.username != "dongtai") {
        return;
      }
      let geoDongtaiPointNew = _.uniqBy(geoDongtaiPoint, "lon", "lat");
      geoDongtaiPointNew.forEach((item) => {
        let imgUrl = "image/s3.png";
        let color = "#3AC4FF";
        if (item.level == 3) {
          // 村
          imgUrl = "image/s3.png";
        } else if (item.level == 2) {
          // 镇
          imgUrl = "image/s3-2.png";
          color = "#FEFF3A";
        } else if (item.level == 1) {
          // 市
          imgUrl = "image/s3-3.png";
          color = "#FF3956";
        }
        let position = Cesium.Cartesian3.fromDegrees(item.lon, item.lat);
        let en = new Cesium.Entity({
          position: position,
          billboard: {
            show: true,
            width: 29,
            height: 38,
            image: imgUrl,
            // horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            scaleByDistance: new Cesium.NearFarScalar(1000, 1.0, 300000, 0.0),
            // pixelOffset: new Cesium.Cartesian2(0, -15)
            disableDepthTestDistance: 10000000000,
          },
          label: {
            text: item.name,
            scale: 0.5,
            font: "bold 30px Microsoft YaHei",
            fillColor: Cesium.Color.fromCssColorString(color),
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.TOP,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            // showBackground: true,
            pixelOffset: new Cesium.Cartesian2(0, -55),
            // backgroundPadding: new Cesium.Cartesian2(10, 10),
            translucencyByDistance: new Cesium.NearFarScalar(
              1000,
              1.0,
              50000,
              0.0
            ),
          },
        });
        viewer.entities.add(en);
      });
    },
    /**@Description :  ******************************************* 添加东台视频点
     **@Date: 2024-08-19 13:54:06
     */
    // async addDTVideoPoint() {
    //   const res = await API.DTVIDEO.getDeviceMediaUrl(); // 获取视频点的列表
    //   this.videoPointList = res;

    //   this.videoPointList.forEach((item) => {
    //     let imgUrl = "image/v-p.png";
    //     let position = Cesium.Cartesian3.fromDegrees(item.lon, item.lat);
    //     let en = new Cesium.Entity({
    //       name: `dt-video-point-${item.deviceName}`,
    //       deviceCode: item.deviceCode, // 保存 deviceCode
    //       position: position,
    //       billboard: {
    //         show: true,
    //         width: 55,
    //         height: 55,
    //         image: imgUrl,
    //         verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
    //         scaleByDistance: new Cesium.NearFarScalar(1000, 1.0, 300000, 0.0),
    //         disableDepthTestDistance: 10000000000,
    //       },
    //       label: {
    //         text: item.deviceName,
    //         scale: 0.5,
    //         font: "bold 30px Microsoft YaHei",
    //         fillColor: Cesium.Color.fromCssColorString("#ff0000"),
    //         horizontalOrigin: Cesium.VerticalOrigin.CENTER,
    //         verticalOrigin: Cesium.VerticalOrigin.TOP,
    //         disableDepthTestDistance: Number.POSITIVE_INFINITY,
    //         pixelOffset: new Cesium.Cartesian2(0, -55),
    //         translucencyByDistance: new Cesium.NearFarScalar(
    //           1000,
    //           1.0,
    //           50000,
    //           0.0
    //         ),
    //       },
    //       data: item,
    //     });

    //     viewer.entities.add(en);
    //   });

    //   // 点击事件处理
    //   const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
    //   handler.setInputAction(async (movement) => {
    //     const pickedObject = viewer.scene.pick(movement.position);
    //     if (
    //       Cesium.defined(pickedObject) &&
    //       pickedObject.id &&
    //       pickedObject.id.data
    //     ) {
    //       const videoData = pickedObject.id.data;
    //       const deviceCode = videoData.deviceCode;

    //       // 获取视频的实时 URL
    //       try {
    //         const res = await API.DTVIDEO.getDeviceMediaUrlHls({ deviceCode });
    //         if (res && res.length > 0) {
    //           const videoUrlData = res[0];
    //           console.log("resresresres", res);

    //           if (videoUrlData.code === 20012) {
    //             // 设备不在线，显示原生 alert 提示
    //             alert("当前设备不在线");
    //             return; // 阻止进一步操作
    //           } else {
    //             // 设备在线，处理 URL
    //             videoData.url = videoUrlData.url.replace(
    //               "https://vnet-shanghaihls.dlife.cn",
    //               dt_video_url
    //             );
    //           }

    //           // 新增视频弹窗实例
    //           this.videoPopups.push({
    //             title: videoData.deviceName,
    //             videoUrl: videoData.url,
    //             fullScreen: false,
    //           });
    //         } else {
    //           console.error("未能获取到有效的视频 URL");
    //         }
    //       } catch (error) {
    //         console.error("获取视频 URL 失败", error);
    //       }
    //     }
    //   }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    // },

    async addDTVideoPoint() {
      const res = await API.DTVIDEO.getDeviceMediaUrl(); // 获取视频点的列表
      this.videoPointList = res;

      this.videoPointList.forEach((item) => {
        let imgUrl = "image/v-p.png";
        let position = Cesium.Cartesian3.fromDegrees(item.lon, item.lat);
        let en = new Cesium.Entity({
          name: `dt-video-point-${item.deviceName}`,
          deviceCode: item.deviceCode, // 保存 deviceCode
          position: position,
          billboard: {
            show: true,
            width: 55,
            height: 55,
            image: imgUrl,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            scaleByDistance: new Cesium.NearFarScalar(1000, 1.0, 300000, 0.0),
            disableDepthTestDistance: 10000000000,
          },
          label: {
            text: item.deviceName,
            scale: 0.5,
            font: "bold 30px Microsoft YaHei",
            fillColor: Cesium.Color.fromCssColorString("#ff0000"),
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.TOP,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            pixelOffset: new Cesium.Cartesian2(0, -55),
            translucencyByDistance: new Cesium.NearFarScalar(
              1000,
              1.0,
              50000,
              0.0
            ),
          },
          data: item,
        });

        viewer.entities.add(en);
      });

      // 点击事件处理
      const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      handler.setInputAction(async (movement) => {
        const pickedObject = viewer.scene.pick(movement.position);
        if (
          Cesium.defined(pickedObject) &&
          pickedObject.id &&
          pickedObject.id.data
        ) {
          const videoData = pickedObject.id.data;
          const deviceCode = videoData.deviceCode;

          // 获取视频的实时 URL
          try {
            const res = await API.DTVIDEO.getDeviceMediaUrlHls({ deviceCode });
            if (res && res.length > 0) {
              const videoUrlData = res[0];

              try {
                // 尝试解析 URL 是否为 JSON 格式
                const parsedData = JSON.parse(videoUrlData.url);
                console.log(parsedData);
                if (parsedData.code === 20012) {
                  // 设备不在线，使用 Element UI 的 Message 组件
                  Message({
                    message: "当前设备不在线",
                    type: "warning",
                    duration: 3000,
                  });
                  return; // 阻止进一步操作
                }
              } catch (e) {
                // 如果解析失败，说明 URL 是正常的视频地址
                videoData.url = videoUrlData.url.replace(
                  "https://vnet-shanghaihls.dlife.cn",
                  dt_video_url
                );
              }

              // 新增视频弹窗实例
              this.videoPopups.push({
                title: videoData.deviceName,
                videoUrl: videoData.url,
                fullScreen: false,
              });
            } else {
              console.error("未能获取到有效的视频 URL");
            }
          } catch (error) {
            console.error("获取视频 URL 失败", error);
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },

    closeVideo(index) {
      this.videoPopups.splice(index, 1); // 关闭视频弹窗
    },

    toggleFullScreen(index) {
      this.$set(this.videoPopups, index, {
        ...this.videoPopups[index],
        fullScreen: !this.videoPopups[index].fullScreen,
      });
    },

    handleFullScreen() {
      this.fullScreen = !this.fullScreen; // 全屏切换
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-home-cesium_layer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;

  #layer-container {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;

    &.bk1027 {
      background-image: url(~@/assets/images/cesium/bk1027.png);
    }
  }

  // 机巢无人机切换视频时 ，地图的位置
  .ycFlag_01 {
    position: fixed !important;
    // right: 0;
    z-index: 11;
    left: 15px !important;
    bottom: calc(23% - 2px) !important;
    width: 349px !important;
    height: 200px !important;
    transition: left 0.3s, opacity 0s;

    &.ycFlag_01--collapse {
      left: -354px !important;
      opacity: 0;
    }
  }

  .ycFlag_01_full {
    position: fixed !important;
    z-index: 11;
    left: 15px !important;
    bottom: calc(33% - 2px) !important;
    width: 349px !important;
    height: 200px !important;
    transition: left 0.3s, opacity 0s;

    &.ycFlag_01_full--collapse {
      left: -354px !important;
      opacity: 0;
    }
  }

  .ycFlag_02 {
    position: fixed !important;
    z-index: 11;
    left: 15px !important;
    bottom: calc(46% - 2px) !important;
    width: 170px !important;
    height: 100px !important;
    transition: left 0.3s, opacity 0s;

    &.ycFlag_02--collapse {
      left: -354px !important;
      opacity: 0;
    }
  }

  .ycFlag_02_full {
    position: fixed !important;
    z-index: 11;
    left: 15px !important;
    bottom: calc(53% - 2px) !important;
    width: 170px !important;
    height: 100px !important;
    transition: left 0.3s, opacity 0s;

    &.ycFlag_02--collapse {
      left: -354px !important;
      opacity: 0;
    }
  }

  .ycFlag_03 {
    position: fixed !important;
    z-index: 11;
    left: 191px !important;
    bottom: calc(46% - 2px) !important;
    width: 170px !important;
    height: 100px !important;
    transition: left 0.3s, opacity 0s;

    &.ycFlag_02--collapse {
      left: -354px !important;
      opacity: 0;
    }
  }

  .ycFlag_03_full {
    position: fixed !important;
    z-index: 11;
    left: 191px !important;
    bottom: calc(53% - 2px) !important;
    width: 170px !important;
    height: 100px !important;
    transition: left 0.3s, opacity 0s;

    &.ycFlag_03--collapse {
      left: -354px !important;
      opacity: 0;
    }
  }

  .yc1027 {
    left: 14px !important;
    top: 532px;
    transition: none;
  }

  .uav1027 {
    left: 18px !important;
    top: calc(72% - 2px) !important;
  }

  .wrjFlag {
    position: fixed !important;
    right: 0;
    z-index: 11;
    left: 24px !important;
    bottom: 80px !important;
    // top: calc(30% - 192px);
    width: 349px !important;
    height: 200px !important;
  }

  .wrjFlag1 {
    position: fixed !important;
    right: 0;
    z-index: 11;
    left: 24px !important;
    top: calc(49% - 238px) !important;
    width: 349px !important;
    height: 200px !important;
  }

  .fkVideo {
    position: fixed !important;
    right: 0;
    z-index: 11;
    left: 16px !important;
    left: 80px !important;
    bottom: 10px !important;
    bottom: 0px !important;
    // width: 429px !important;
    // width: 390px !important;
    width: 410px !important;
    height: 223px !important;
  }
}
</style>
