// 创建弹窗对象的方法
var Popup = function (info) {
  this.constructor(info);
};
Popup.prototype.id = 0;
Popup.prototype.constructor = function (info) {
  console.log("HomePopup-info", info);
  var _this = this;
  _this.viewer = info.viewer; //弹窗创建的viewer
  _this.geometry = info.geometry; //弹窗挂载的位置
  _this.data = info.data;
  _this.del_real = info.del_real;
  console.log("HomePopup-info.this---***>", info.arr);
  _this.vueThis = info.arr;
  // if(info.hasOwnProperty('arr')) {
  //   _this.arr = info.arr
  // }
  _this.id = "popup_" + _this.__proto__.id++;
  _this.ctn = $("<div class='home-popup-ctn' id='" + _this.id + "'>");
  $(_this.viewer.container).append(_this.ctn);

  let contentHtml = `<img src="${_this.data.preImg}" crossOrigin="anonymous" alt=""/>`;
  contentHtml += `<div class="span-text">`;
  /**
   * 增加单位名称
   */
  if (_this.data?.name) {
    contentHtml += `<span class="titleText">${_this.data.name}</span>`;
  }
  if (_this.data?.address) {
    contentHtml += `<span class="subChildText">${_this.data.address}</span>`;
  }
  contentHtml += `</div>`;
  //测试弹窗内容
  var testConfig = {
    header: _this.data.name || _this.data.carNo || "",
    content: `${contentHtml}`,
  };
  _this.ctn.append(_this.createHtml(testConfig.content));
  // 实时刷新
  _this.offsetWidth = _this.ctn.get(0).offsetWidth;
  _this.offsetHeight = _this.ctn.get(0).offsetHeight;

  _this.render(_this.geometry);
  // _this.eventListener = _this.viewer.clock.onTick.addEventListener(function (clock) {
  //   _this.render(_this.geometry);
  // })

  _this.eventListener = () => {
    // 每一帧都去计算气泡的正确位置
    _this.render(_this.geometry);
  };
  _this.viewer.scene.postRender.addEventListener(_this.eventListener);

  _this.ctn.on("click", ".leaflet-popup-close-button", function (e) {
    e.stopPropagation();
    _this.close();
  });
};
Popup.prototype.render = function (geometry) {
  // console.log(geometry, 'render()...geometry');
  if (!geometry) return;
  var _this = this;
  var position = Cesium.SceneTransforms.wgs84ToWindowCoordinates(
    _this.viewer.scene,
    geometry
  );
  // _this.ctn.css("position","absolute");
  _this.ctn.css("left", position.x - _this.offsetWidth / 2);
  _this.ctn.css("top", position.y - _this.offsetHeight - 10);
};
// 动态生成内容
Popup.prototype.createHtml = function (content) {
  var html =
    '<div class="home-popup-content-ctn" >' +
    '<div class="home-popup-content" >' +
    content +
    "</div>" +
    "</div>" +
    '<div class="bx-popup-tip-container" >' +
    '<div class="bx-popup-tip" >' +
    "</div>" +
    "</div>" +
    '<div class="el-icon-close leaflet-popup-close-button"></div>';
  return html;
};
// 关闭弹窗按钮
Popup.prototype.close = function () {
  var _this = this;
  _this.ctn.remove();
  // _this.viewer.clock.onTick.removeEventListener(_this.eventListener);
  _this.viewer.scene.postRender.removeEventListener(_this.eventListener);
  // 防止点击关闭无法创建新的移动弹窗
  console.log("this.data", this.data);
  const index = _this.vueThis.move_id_arr.indexOf("_this.data.id");
  _this.vueThis.move_id_arr.splice(index, 1);
};

export default Popup;
